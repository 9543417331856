import request from '@wearetla/tla-essentials-tools/utilities/request';

const storeServices = {
	// Location
	getStore: (storeID) => {
		return new Promise((resolve, reject) => {
			request.get(`store/detail/${storeID}`, {}, (payload, status, request) => {
				if (payload?.store) {
					resolve(payload.store);
				}
				else {
					reject(request);
				}
			});
		});
	},
	locationLookup: (latitude, longitude) => {
		return new Promise((resolve, reject) => {
			request.post('store/list', { latitude: latitude.toString(), longitude: longitude.toString() }, (payload, status, request) => {
				if (payload?.stores) {
					resolve({
						store: payload.stores[0] ?? false,
						addressInfo: payload.reverse_geocode,
					});
				}
				else {
					reject(request?.data?.meta?.messages || 'Konumunuz getirilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
}

export default storeServices;