const childrenGenders = [
	{
		value: 2,
		key: 'girl',
		label: 'Kız',
	},
	{
		value: 1,
		key: 'boy',
		label: 'Erkek',
	}
]

export default childrenGenders