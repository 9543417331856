import { useMemo, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

// Context
import { AppNotificationsContext } from './app-notifications-context'
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state';

// Services
import siteServices from '/services/site'

export const AppNotificationsProvider = ({ children }) => {
	const { isApplication } = useGlobalState();
	const [lastSeenId, setLastSeenId] = useState(0)
	const [rawNotifications, setNotifications] = useState(false);

	const { notifications, unreadCount: notificationCount } = useMemo(() => {
		if(!rawNotifications) {
			return { notifications: [false, false, false, false, false], unreadCount: 0 }
		}

		try {
			return (rawNotifications || []).reduce(({notifications, unreadCount}, notification) => {
				const unread = notification.id > lastSeenId;
	
				return {
					notifications: [
						...notifications,
						{
							...notification,
							unread,
						}
					],
					unreadCount: unreadCount + (unread ? 1 : 0),
				};
			}, { notifications: [], unreadCount: 0 });
		}
		catch(e) {
			console.warn(e);
		}
	}, [rawNotifications, lastSeenId]);

	const getNotifications = useCallback(() => {
		return new Promise ((resolve, reject) => {
			siteServices.getNotifications().then((notifications) => {
				setNotifications(notifications);
				resolve(notifications);
			}).catch(reject);
		});
	}, []);

	const readNotifications = () => {
		const newLastSeen = rawNotifications[0]?.id;
		if(newLastSeen) {
			setLastSeenId(newLastSeen);
			window.localStorage?.setItem('notifications-last-seen', newLastSeen);
		}
	};

	useEffect(() => {
		if(isApplication) {
			getNotifications();
			const storageId = window.localStorage?.getItem('notifications-last-seen');
			setLastSeenId(storageId ? parseInt(storageId) : 0);
		}
	}, [isApplication, getNotifications])

	return <AppNotificationsContext.Provider value={{
		notifications,
		notificationCount,
		getNotifications,
		readNotifications,
	}}>
		{children}
	</AppNotificationsContext.Provider>
}

AppNotificationsProvider.propTypes = {
	children: PropTypes.node,
}