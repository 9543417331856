import config from '/config';

// Context
import { useApplication } from '/utilities/application';

export const SegmentifyController = () => {
  const { isApplication } = useApplication();

  return (
    <input type="hidden" name={config.segmentify?.appStateInputName ?? 'segmentify-mobile-state'} value={isApplication ? '1' : '0'} />
  )
}

export default SegmentifyController;