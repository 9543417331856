import categoryFilters from '/data/listing-filters/types/category'
import defaultFilter from '/data/listing-filters/default-filter.js'

const customListFilters = {
	...categoryFilters,
	categories: {
		...defaultFilter,
		title: 'Kategoriler',
		singleTitle: 'Kategori',
		search: true,
		searchText: 'Kategori ara',
		hideFromSum: true,
	},
	today_cargo: {
		...defaultFilter,
		type: 'default',
		title: "Aynı Gün Kargo",
		singleTitle: "Aynı Gün Kargo",
		dataType: 'bool',
		hide: true,
	},
	stock: {
		...defaultFilter,
		type: 'default',
		title: "Sadece Stoktakiler",
		singleTitle: "Sadece Stoktakiler",
		dataType: 'bool',
		hide: true,
	},
}

export default customListFilters