export const generateInsetStyles = (insets) => {
  try {
    return `
      :root {
        --safe-area-insets-top: ${Math.ceil(insets?.top ?? 0)}px;
        --safe-area-insets-bottom: ${Math.ceil(insets?.bottom ?? 0)}px;
        --safe-area-insets-left: ${Math.ceil(insets?.left ?? 0)}px;
        --safe-area-insets-right: ${Math.ceil(insets?.right ?? 0)}px;
      }
    `
  }
  catch(e) {
    console.log('Error rendering insets styles', e);
  }

  return ``
}

export default generateInsetStyles;