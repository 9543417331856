import { createContext } from 'react'

// Data
import defaultChildSelection from '/data/default-child-selection';

export const ChildrenContext = createContext({
	children: [],
	childSelection: {
		...defaultChildSelection
	},
});