import config from '/config';
import loadable from '@loadable/component';

const SentryProvider = loadable(() => import('./sentry-provider'));

export const LazySentryProvider = ({ children, serverProps }) => {

	if(config.sentry?.enabled && config.sentry.dsn) {
		return <SentryProvider serverProps={serverProps}>{children}</SentryProvider>
	}
	else {
		return children;
	}
}

export default LazySentryProvider;