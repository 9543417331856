const registeredModals = {
	'product-installment-options': () => (import('./product-installment-options')),
	'product-gallery': () => (import('./product-gallery')),
	'options': () => (import('./options')),
	'confirm': () => (import('./confirm')),
	'alert': () => (import('./alert')),
	'agreement': () => (import('./agreement')),
	'agreementUpdate': () => (import('./agreement-update')),
	'message': () => (import('./message')),
	'product-basket-message': () => (import('./product-basket-message')),
	'login-message': () => (import('./login-message')),
	'delivery-address': () => (import('./delivery-address')),
	'invoice-address': () => (import('./invoice-address')),
	'checkout-products': () => (import('./checkout-products')),
	'child': () => (import('./child')),
	'gift-code-detail': () => (import('./gift-code-detail')),
	'store-stock-lookup': () => (import('./store-stock-lookup')),
	'store-map': () => (import('./store-map')),
	'basket-promo': () => (import('./basket-promo')),
	'product-comparison': () => (import('./product-comparison')),
	'product-comment': () => (import('./product-comment')),
	'size-chart': () => (import('./size-chart')),
	'product-return': () => (import('./product-return')),
	'otp': () => (import('./otp')),
	'basket-stock-warnings': () => (import('./basket-stock-warnings')),
	'basket-gift': () => (import('./basket-gift')),
	'campaign': () => (import('./campaign')),
	'notifications': () => (import('./notifications')),
	'popup': () => (import('./popup')),
	'cancelMembership': () => (import('./cancel-membership')),
	'guestLogin': () => (import('./guest-login')),
}

export default registeredModals;