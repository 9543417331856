// Data
// import listingFilters from 'data/listing-filters'
import listingFilters from '/data/listing-filters'

const parseListingFilters = (payload, pageType = 'category') => {
	let filters = [];

	try {
		for (const filterKey in listingFilters[pageType]) {
			const isObject = typeof payload[filterKey] === 'object' && !Array.isArray(payload[filterKey]) && Object.keys(payload[filterKey]).length;
			if (payload[filterKey] && (isObject || payload[filterKey].length)) {
				let data = [];
	
				if(isObject) {
					for(const key in payload[filterKey]) {
						data.push({
							title: key,
							filters: payload[filterKey][key],
						})
					}
				}
				else {
					data = payload[filterKey]
				}
	
				filters.push({
					key: filterKey,
					pageType: pageType,
					...listingFilters[pageType][filterKey],
					data: data,
				})
			}
		}

		if(listingFilters[pageType]['today_cargo']) {
			filters.push({
				key: 'today_cargo',
				pageType: pageType,
				...listingFilters[pageType]['today_cargo'],
				data: [],
			})
		}
		if(listingFilters[pageType]['stock']) {
			filters.push({
				key: 'stock',
				pageType: pageType,
				...listingFilters[pageType]['stock'],
				data: [],
			})
		}
	}
	catch(e) {
		console.warn('Error parsing listing filters:', e);
	}

	return filters;
}

export default parseListingFilters