import { StrictMode, Fragment } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { loadableReady } from '@loadable/component';
import loadable from '@loadable/component';
import config from '/config';

import App from '/app'

globalThis.isClient = true;

const StorageError = loadable(() => import('./views/pages/storage-error'), { ssr: false });

function testLocalStorage() {
  try {
		if(!("localStorage" in window)) { return false }
    const key = "_test_local_storage";
    localStorage?.setItem(key, key);
    localStorage?.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
}

loadableReady(() => {
	try {
		const OuterWrap = config.strictMode === true ? StrictMode : Fragment;

		if(testLocalStorage()) {
			hydrateRoot(
				document.getElementById('root'),
				<OuterWrap>
					<BrowserRouter>
						<App />
					</BrowserRouter>
				</OuterWrap>
			);
		}
		else {
			hydrateRoot(
				document.getElementById('root'),
				<OuterWrap>
					<StorageError />
				</OuterWrap>
			);
		}
	}
	catch(e) {
		console.error('Hydration error:', e);
	}
});
