const getMobileOS = () => {
	if(navigator?.userAgent) {
		if (/android/i.test(navigator.userAgent)) {
			return 'Android';
		}
		else if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
			return 'Ios';
		}
		else {
			return 'Web';
		}

	}
	else {
		return 'Web';
	}
}

export default getMobileOS;