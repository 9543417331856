const orderStatusCodes = {
	'default': {
		text: 'Varsayılan',
		status: 'on-hold',
		icon: 'history',
	},
	0: {
		text: 'İşlem Devam Ediyor / Yarım',
		status: 'on-hold',
		icon: 'history',
	},
	1: {
		text: 'Yeni Sipariş',
		status: 'on-hold',
		icon: 'history',
		// cancelMode: true,
	},
	2: {
		text: 'Sipariş Onaylandı',
		status: 'on-hold',
		icon: 'history',
		// cancelMode: true,
	},
	3: {
		text: 'Arıza Kontrol',
		status: 'on-hold',
		icon: 'history',
	},
	4: {
		text: 'İade / İptal Talep Edildi',
		status: 'on-hold',
		icon: 'history',
	},
	5: {
		text: 'Kargoya Verildi',
		status: 'shipping',
		icon: 'cargo',
	},
	6: {
		text: 'Teslim edildi',
		status: 'success',
		icon: 'check-box',
	},
	7: {
		text: 'İptal Edildi',
		status: 'canceled',
		icon: 'history',
		returnable: false,
		commentable: false,
	},
	8: {
		text: 'İade Edildi',
		status: 'canceled',
		icon: 'history',
		returnable: false,
	},
	9: {
		text: 'Havale Bekleniyor',
		status: 'on-hold',
		icon: 'history',
		// cancelMode: true,
	},
	10: {
		text: 'Onay Bekleniyor',
		status: 'on-hold',
		icon: 'history',
	},
	11: {
		text: 'Değişim',
		status: 'on-hold',
		icon: 'history',
	},
	12: {
		text: 'Parça Talebi',
		status: 'on-hold',
		icon: 'history',
	},
	13: {
		text: 'Hasar Sigorta',
		status: 'on-hold',
		icon: 'history',
	},
	14: {
		text: 'Tedarikçide',
		status: 'on-hold',
		icon: 'history',
	},
	15: {
		text: 'Onay Bekleniyor',
		status: 'on-hold',
		icon: 'history',
	},
	16: {
		text: 'Kayıp Kargo',
		status: 'on-hold',
		icon: 'history',
	},
	17: {
		text: 'Ürünler Tamam - Kargo Bekliyor',
		status: 'on-hold',
		icon: 'history',
	},
	18: {
		text: 'Havale Onaylandı',
		status: 'on-hold',
		icon: 'history',
		// cancelMode: true,
	},
	20: {
		text: 'Sipariş Hazırlanıyor',
		status: 'on-hold',
		icon: 'history',
	},
	21: {
		text: 'Ürünler Paketleniyor',
		status: 'on-hold',
		icon: 'history',
	},
	35: {
		text: 'İade / İptal Ürünler Onaylandı',
		status: 'on-hold',
		icon: 'history',
	},
	36: {
		text: 'Onaylanmayan İade Gönderimi Yapılacak',
		status: 'on-hold',
		icon: 'history',
	},
	37: {
		text: 'İade Kabul Edildi',
		status: 'success',
		icon: 'check-box',
	},
	38: {
		text: 'Geri Ödeme / Mal Gönderimi Tamamlandı',
		status: 'canceled',
		icon: 'check-box',
	},
	51: {
		text: 'Yeni Sipariş',
		status: 'on-hold',
		icon: 'history',
		// cancelMode: true,
	},
	52: {
		text: 'Sipariş Hazırlanıyor',
		status: 'on-hold',
		icon: 'history',
		// cancelMode: true,
	},
	53: {
		text: 'Sipariş Hazır',
		status: 'on-hold',
		icon: 'history',
		// cancelMode: true,
	},
	54: {
		text: 'Teslim Alındı',
		status: 'on-hold',
		icon: 'history',
	},
	55: {
		text: 'Teslim Alınmadı',
		status: 'on-hold',
		icon: 'history',
	},
	56: {
		text: 'İadeli Sipariş',
		status: 'on-hold',
		icon: 'history',
	},
	57: {
		text: 'İptal Edildi',
		status: 'canceled',
		icon: 'check-box',
	},
	58: {
		text: 'Tümü iade',
		status: 'canceled',
		icon: 'check-box',
	},
	59: {
		text: 'Siparş Hazırlandı',
		status: 'on-hold',
		icon: 'history',
	},
	99: {
		text: 'Sipariş Sürecinde',
		status: 'on-hold',
		icon: 'history',
	},
	999: {
		text: 'İade Süreci',
		status: 'canceled',
		icon: 'history',
	},
}

export default orderStatusCodes