const defaultFilter = {
	type: 'default',
	title: 'Filtre',
	search: false,
	searchText: false,
	sort: false,
	dataType: 'array',
	hideFromSum: false,
}

export default defaultFilter