import image_age_mom_preg from '/assets/images/home-ages/anne-ve-hamile.png'
import image_age_0_3 from '/assets/images/home-ages/0-3.jpg'
import image_age_3_6 from '/assets/images/home-ages/3-6.jpg'
import image_age_6_9 from '/assets/images/home-ages/6-9.jpg'
import image_age_9_12 from '/assets/images/home-ages/9-12.jpg'
import image_age_12_18 from '/assets/images/home-ages/12-18.jpg'
import image_age_18_24 from '/assets/images/home-ages/18-24.jpg'
import image_age_2 from '/assets/images/home-ages/2.jpg'

const ageGroups = [
	{
		start_months: -2,
		end_months: -1,
		value: 'Anne ve Hamile',
		searchValue: '-2,-1',
		label: 'Anne ve Hamile',
		image: image_age_mom_preg,
		list: {
			slug: 'anne-hamile',
			id: 2273,
		},
	},
	{
		start_months: 0,
		end_months: 3,
		value: '0-3',
		searchValue: '0,0.3',
		label: '0-3 Ay',
		image: image_age_0_3,
		list: {
			slug: '0-3-ay-bebek-urunleri',
			id: 5,
		},
	},
	{
		start_months: 3,
		end_months: 6,
		value: '3-6',
		searchValue: '0.3,0.6',
		label: '3-6 Ay',
		image: image_age_3_6,
		list: {
			slug: '3-6-ay-bebek-urunleri',
			id: 6,
		},
	},
	{
		start_months: 6,
		end_months: 9,
		value: '6-9',
		searchValue: '0.6,0.9',
		label: '6-9 Ay',
		image: image_age_6_9,
		list: {
			slug: '6-9-ay-bebek-urunleri',
			id: 7,
		},
	},
	{
		start_months: 9,
		end_months: 12,
		value: '9-12',
		searchValue: '0.9,1',
		label: '9-12 Ay',
		image: image_age_9_12,
		list: {
			slug: '9-12-ay-bebek-urunleri',
			id: 8,
		},
	},
	{
		start_months: 12,
		end_months: 18,
		value: '12-18',
		searchValue: '1,1.5',
		label: '12-18 Ay',
		image: image_age_12_18,
		list: {
			slug: '12-18-ay-bebek-urunleri',
			id: 9,
		},
	},
	{
		start_months: 18,
		end_months: 24,
		value: '18-24',
		searchValue: '1.5,2',
		label: '18-24 Ay',
		image: image_age_18_24,
		list: {
			slug: '18-24-ay-bebek-urunleri',
			id: 10,
		},
	},
	{
		start_months: 24,
		end_months: false,
		value: '24',
		searchValue: '2,20',
		label: '2 Yaş+',
		image: image_age_2,
		list: {
			slug: '2-yas-ve-ustu-bebek-urunleri',
			id: 11,
		},
	},
]

export default ageGroups