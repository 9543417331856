import config from '/config';
import request from '@wearetla/tla-essentials-tools/utilities/request';

// Functions
import formatPhone from '@wearetla/tla-essentials-tools/functions/format-phone';
import formatDate from '/functions/format-date';
import birthdateToAge from '/functions/birthdate-to-age';
import monthsToAgeGroup from '/functions/months-to-age-group';
import processOrderData from '/functions/process-order-data';

// Data
import orderStatusCodes from '/data/order-status-codes';
import childGenders from '/data/genders-children';

const userServices = {
	// Info
	updateInfo: (userData) => {
		return new Promise((resolve, reject) => {
			request.put(`member`, {
				...userData,
			}, (payload, status, request) => {
				if (status === 200) {
					resolve(request?.data?.meta?.messages || { success: ['Üyelik bilgileriniz güncellendi.']});
				}
				else {
					reject(request?.data?.meta?.messages || 'Kayıt işlemi gerçekleştirilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	getUserData: () => {
		return new Promise((resolve, reject) => {
			request.get(`member/me`, {}, (payload, status, request) => {
				if (
					status === 200 &&
					payload?.member
				) {
					resolve(payload.member);
				}
				else {
					reject(request);
				}
			});
		});
	},
	// Auth
	getGuestToken: () => {
		return new Promise((resolve, reject) => {
			request.get(`users/init`, {}, (payload, status, request) => {
				if(payload?.guest_token) {
					resolve(payload.guest_token);
				}
				else {
					reject(request);
				}
			});
		});
	},
	logout: () => {
		return new Promise((resolve, reject) => {
			request.delete(`users/logout`, {}, (payload, status, request) => {
				if(status === 200) {
					resolve();
				}
				else {
					reject(request);
				}
			});
		});
	},
	// Otp
	sendOTP: (phoneNumber, action = 'login') => {
		return new Promise((resolve, reject) => {
			request.post(`otp/send`, {
				mobile_number: phoneNumber,
				token_type: `${action}_token`,
			}, (payload, status, request) => {
				if (status === 200) {
					resolve(true);
				}
				else {
					reject(request?.data?.meta?.messages || 'SMS gönderilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	checkOTP: (phoneNumber, code) => {
		return new Promise((resolve, reject) => {
			request.post(`otp/check`, {
				mobile_number: phoneNumber,
				code,
			}, (payload, status, request) => {
				if (status === 200 && payload.otp_token) {
					resolve(payload.otp_token);
				}
				else {
					reject(request?.data?.meta?.messages || 'SMS onaylanırken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	authenticateOTP: (phoneNumber, otpToken) => {
		return new Promise((resolve, reject) => {
			request.post('users/authenticate', {
				identifier: phoneNumber,
				otp_token: otpToken,
			}, (payload, status, request) => {
				if (status === 200 && payload?.token) {
					resolve(payload.token);
				}
				else {
					reject(request?.data?.meta?.messages || 'Giriş işlemi gerçekleştirilirken bir hata ile karşılaşıldı, lütfen tekrar deneyin.');
				}
			});
		});
	},
	// Register
	register: (userData) => {
		return new Promise((resolve, reject) => {
			request.post(
				'users/register',
				userData,
				function (payload, status, request) {
					if (status === 200 && payload.token) {
						resolve(payload.token);
					}
					else {
						reject(request.data.meta ? request.data.meta.messages : 'Üye kaydı yapılırken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				}
			);
		});
	},
	guestRegister: (userData) => {
		return new Promise((resolve, reject) => {
			request.post(
				'users/guest-register',
				userData,
				function (payload, status, request) {
					if (status === 200 && payload.token) {
						resolve(payload.token);
					}
					else {
						reject(request.data.meta ? request.data.meta.messages : 'Misafir girişi yapılırken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				}
			);
		});
	},
	// Orders
	getOrders: (page = 1) => {
		return new Promise((resolve, reject) => {
			request.get(
				'member/orders',
				{ page: page },
				function (payload, status, request) {
					if (status === 200 && payload && payload.orders) {
						resolve({
							orders: payload.orders.map((order) => ({
								...order,
								status_object: orderStatusCodes[order.status] ? orderStatusCodes[order.status] : orderStatusCodes['default'],
							})),
							pagination: request.data.pagination,
						});
					}
					else {
						reject(request.data.meta ? request.data.meta.messages : 'Siparişler getirilirken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				},
				{ });
		});
	},
	getCanceledOrders: (page = 1) => {
		return new Promise((resolve, reject) => {
			request.get(
				'member/orders/canceled',
				{ page: page },
				function (payload, status, request) {
					if (status === 200 && payload && payload.orders) {
						resolve({
							orders: payload.orders.map((order) => ({
								...order,
								status_object: orderStatusCodes[order.status] ? orderStatusCodes[order.status] : orderStatusCodes['default'],
							})),
							pagination: request.data.pagination,
						});
					}
					else {
						reject(request.data.meta ? request.data.meta.messages : 'Siparişler getirilirken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				},
				{ });
		});
	},
	getOrderDetail: (orderID) => {
		return new Promise((resolve, reject) => {
			request.get(
				`member/orders/${orderID}`,
				false,
				function (payload, status, request) {
					if (status === 200 && payload && payload.order) {
						resolve(processOrderData(payload.order));
					}
					else {
						reject(request.data.meta ? request.data.meta.messages : 'Sipariş getirilirken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				},
				{ });
		});
	},
	// Children
	getChild: (childID) => {
		return new Promise((resolve, reject) => {
			request.get(
				`member/children/${childID}`,
				false,
				function (payload, status, request) {
					if (status === 200 && payload && payload.child) {
						resolve(payload.child);
					}
					else {
						reject(request.data.meta ? request.data.meta.messages : 'Bebek bilgisi getirilirken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				},
				{ });
		});
	},
	getChildren: () => {
		return new Promise((resolve, reject) => {
			request.get(
				'member/children',
				false,
				function (payload, status, request) {
					if (status === 200 && payload && payload.children) {
						const children = payload.children.map((child, nth) => {
							const monthsAge = birthdateToAge(child.birthdate, true);

							return {
								...child,
								monthsAge: monthsAge,
								ageGroup: monthsToAgeGroup(monthsAge),
								genderData: childGenders.find(g => g.value === child.gender),
							}
						})
						resolve(children);
					}
					else {
						reject(request.data.meta ? request.data.meta.messages : 'Bebekler getirilirken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				},
				{ }
			);
		});
	},
	addChild: (childData) => {
		return new Promise((resolve, reject) => {
			request.post(
				'member/children/add',
				childData,
				function (payload, status, request) {
					if (status === 200) {
						resolve();
					}
					else {
						reject(request);
					}
				},
				{ showMessages: ['warning'] });
		});
	},
	updateChild: (childID, childData) => {
		return new Promise((resolve, reject) => {
			request.put(
				`member/children/${childID}`,
				childData,
				function (payload, status, request) {
					if (status === 200) {
						resolve();
					}
					else {
						reject(request);
					}
				},
				{ showMessages: ['warning'] });
		});
	},
	addChildren: (children) => {
		return new Promise((resolve, reject) => {
			request.post(
				'member/children/add-multiple',
				{children: children},
				function (payload, status, request) {
					if (status === 200) {
						resolve();
					}
					else {
						reject(request);
					}
				},
				{ showMessages: ['warning'] });
		});
	},
	removeChild: (childID) => {
		return new Promise((resolve, reject) => {
			request.delete(
				`member/children/${childID}`,
				false,
				function (payload, status, request) {
					if (status === 200) {
						resolve(payload);
					}
					else {
						reject(request.data.meta ? request.data.meta.messages : 'Bebek bilgileri kaldırılırken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				},
				{ });
		});
	},
	// Agreements
	getAgreement: (key, parameter) => {
		return new Promise((resolve, reject) => {
			request.get(
				`agreements/${key}${parameter ? `/${parameter}`: ''}`,
				false,
				function (payload, status, request) {
					if (status === 200 && payload) {
						resolve(payload);
					}
					else {
						reject(request.data.meta ? request.data.meta.messages : 'Sözleşme getirilirken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				},
				{});
		});
	},
	checkAgreementUpdates: () => {
		return new Promise((resolve, reject) => {
			request.get(
				`member/terms`,
				false,
				function (payload, status, request) {
					if (status === 200 && payload && payload.required_agreements) {
						resolve(payload.required_agreements);
					}
					else {
						reject(request.data.meta ? request.data.meta.messages : 'Sözleşme getirilirken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				},
				{});
		});
	},
	updateAgreements: (agreements) => {
		return new Promise((resolve, reject) => {
			request.post(
				`member/terms`,
				{ agreements: agreements },
				function (payload, status, request) {
					if (status === 200) {
						resolve(payload);
					}
					else {
						reject(request.data.meta ? request.data.meta.messages : `Sözleşme${agreements.length > 1 ? 'ler' : ''} güncellenirken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.`);
					}
				},
				{ showMessages: false }
			);
		});
	},
	// Messages
	getMessages: () => {
		return new Promise((resolve, reject) => {
			request.get(
				`member/messages`,
				false,
				function (payload, status, request) {
					if (status === 200 && payload && payload.messages) {
						resolve(payload.messages);
					}
					else {
						reject(request.data.meta ? request.data.meta.messages : 'Mesajlarınız getirilirken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				},
				{});
		});
	},
	getMessage: (messageID) => {
		return new Promise((resolve, reject) => {
			request.get(
				`member/messages/${messageID}`,
				false,
				function (payload, status, request) {
					if (status === 200 && payload && payload.message) {
						resolve(payload.message);
					}
					else {
						reject(request.data.meta ? request.data.meta.messages : 'Mesajınız getirilirken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				},
				{});
		});
	},
	createMessage: (formData) => {
		return new Promise((resolve, reject) => {
			request.post(
				`member/messages`,
				formData,
				function (payload, status, request) {
					if (status === 200) {
						resolve(true);
					}
					else {
						reject(request.data.meta ? request.data.meta.messages : 'Mesaj gönderilirken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				},
				{});
		});
	},
	postMessage: (threadID, formData) => {
		return new Promise((resolve, reject) => {
			request.post(
				`member/messages/${threadID}`,
				formData,
				function (payload, status, request) {
					if (status === 200) {
						resolve(true);
					}
					else {
						reject(request.data.meta ? request.data.meta.messages : 'Mesaj gönderilirken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				},
				{});
		});
	},
	getMessageOrders: () => {
		return new Promise((resolve, reject) => {
			request.get(
				`member/messages/sales`,
				false,
				function (payload, status, request) {
					if (status === 200 && payload && payload.sales) {
						resolve(payload.sales.map(s => ({
							...s,
							value: s.id,
							label: <span className="message-order-box">#{s.sale_code} <span>{formatDate(s.created_at)}{orderStatusCodes[s.status] ? (' - ' + orderStatusCodes[s.status].text) : ''}</span></span>,
						})));
					}
					else {
						reject(request.data.meta ? request.data.meta.messages : 'Siparişler getirilirken bir hata ile karşılaşıldı.');
					}
				},
				{});
		});
	},
	// Reviews
	getReviews: () => {
		return new Promise((resolve, reject) => {
			request.get(
				`member/orders/reviews`,
				false,
				function (payload, status, request) {
					if (status === 200 && payload.complete && payload.incomplete) {
						resolve(payload);
					}
					else {
						reject(request.data.meta ? request.data.meta.messages : 'Değerlendirmeleriniz getirilirken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				},
				{ }
			);
		});
	},
	// Favorite Products
	getFavoriteProducts: () => {
		return new Promise((resolve, reject) => {
			request.get(
				`wishlists/favorite`,
				false,
				function (payload, status, request) {
					if (status === 200 && payload.items) {
						resolve(payload.items);
					}
					else {
						reject(request.data.meta ? request.data.meta.messages : 'Favori ürünleriniz getirilirken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				},
				{ }
			);
		});
	},
	addFavoriteProduct: (product) => {
		return new Promise((resolve, reject) => {
			request.post(
				`wishlists/favorite`,
				{
					'product_id': product.id,
					'product_serial_id': product.serial_id,
					'quantity': 1,
					'quantity_type': 1
				},
				function (payload, status, request) {
					if (status === 201 && payload.item_id) {
						resolve(payload);
					}
					else {
						reject(request.data.meta ? request.data.meta.messages : 'Favori ürünleriniz getirilirken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				},
				{});
		});
	},
	removeFavoriteProduct: (product) => {
		return new Promise((resolve, reject) => {
			request.delete(
				`wishlists/items/${product.item_id}`,
				false,
				function (payload, status, request) {
					if (status === 200) {
						resolve(product);
					}
					else {
						reject(request.data.meta ? request.data.meta.messages : 'Ürün favorilerden çıkartılırken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				},
				{});
		});
	},
	// Alarm Products
	getAlarmProducts: () => {
		return new Promise((resolve, reject) => {
			request.get(
				`member/alarms`,
				false,
				function (payload, status, request) {
					if (status === 200 && payload.alarms) {
						const alarmsData = {
							price: payload.alarms[2],
							stock: payload.alarms[3],
						}
						resolve(alarmsData);
					}
					else {
						reject(request.data.meta ? request.data.meta.messages : 'Alarmlarınız getirilirken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				},
				{ }
			);
		});
	},
	addAlarmProduct: (product, type = 'price') => {
		return new Promise((resolve, reject) => {
			const typeID = type === 'price' ? '2' : '3';

			request.post(
				`member/alarms`,
				{
					'type' : typeID,
					'product_id': product.id,
					'product_serial_id' : product.serial_id,
				},
				function (payload, status, request) {
					if (status === 200 && payload.alarm_id) {
						resolve({
							...product,
							alarm_id: payload.alarm_id,
						});
					}
					else {
						console.warn(request);
						reject(request.data.meta ? request.data.meta.messages : 'Favori ürünleriniz getirilirken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				},
				{});
		});
	},
	removeAlarmProduct: (product, type = 'price') => {
		return new Promise((resolve, reject) => {
			request.delete(
				`member/alarms/${product.alarm_id}`,
				false,
				function (payload, status, request) {
					if (status === 200) {
						resolve(product);
					}
					else {
						reject(request.data.meta ? request.data.meta.messages : 'Ürün favorilerden çıkartılırken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				},
				{});
		});
	},
	// Addresses
	getDeliveryAddresses: () => {
		return new Promise((resolve, reject) => {
			request.get(
				`delivery`,
				false,
				function (payload, status, request) {
					if (status === 200 && payload && payload.deliveries) {
						resolve(payload.deliveries.map((delivery) => ({
							...delivery,
							mobile_number: formatPhone(delivery.mobile_number),
						})));
					}
					else {
						reject(request);
					}
				},
				{ });
		});
	},
	getDeliveryAddress: (id) => {
		return new Promise((resolve, reject) => {
			request.get(
				`delivery/${id}`,
				false,
				function (payload, status, request) {
					if (status === 200 && payload && payload.delivery) {
						resolve({
							...payload.delivery,
							mobile_number: formatPhone(payload.delivery.mobile_number),
						});
					}
					else {
						reject(request);
					}
				},
				{}
			);
		});
	},
	addDeliveryAddress: (data) => {
		return new Promise((resolve, reject) => {
			const deliveryData = {
				...data,
				phone_number: data.mobile_number
			}

			request.post(
				`delivery`,
				deliveryData,
				function (payload, status, request) {
					if (status === 200 && payload?.delivery) {
						resolve(payload.delivery);
					}
					else {
						reject(request);
					}
				},
				{}
			);
		});
	},
	updateDeliveryAddress: (data, id) => {
		return new Promise((resolve, reject) => {
			const deliveryData = {
				...data,
				phone_number: data.mobile_number
			}

			request.put(
				`delivery/${id}`,
				deliveryData,
				function (payload, status, request) {
					if (status === 200 && payload) {
						resolve(payload);
					}
					else {
						reject(request);
					}
				},
				{}
			);
		});
	},
	deleteDeliveryAddress: (id) => {
		return new Promise((resolve, reject) => {
			request.delete(
				`delivery/${id}`,
				false,
				function (payload, status, request) {
					if (status === 200) {
						resolve(payload);
					}
					else {
						reject(request);
					}
				},
				{}
			);
		});
	},
	// Invoices
	getInvoiceAddresses: () => {
		return new Promise((resolve, reject) => {
			request.get(
				`invoice`,
				false,
				function (payload, status, request) {
					if (status === 200 && payload && payload.invoices) {
						resolve(payload.invoices.map((invoice) => ({
							...invoice,
							id_number: invoice.id_number === config.undefinedTaxNumber ? '' : invoice.id_number,
							mobile_number: formatPhone(invoice.mobile_number),
						})));
					}
					else {
						reject(request);
					}
				},
				{ });
		});
	},
	getInvoiceAddress: (id) => {
		return new Promise((resolve, reject) => {
			request.get(
				`invoice/${id}`,
				false,
				function (payload, status, request) {
					if (status === 200 && payload && payload.invoice) {
						resolve({
							...payload.invoice,
							id_number: payload.invoice.id_number === config.undefinedTaxNumber ? '' : payload.invoice.id_number,
							tax_number: payload.invoice.tax_number === config.undefinedTaxNumber ? '' : payload.invoice.tax_number,
							tax_office: payload.invoice.tax_office === config.undefinedTaxOffice ? '' : payload.invoice.tax_office,
						});
					}
					else {
						reject(request);
					}
				},
				{}
			);
		});
	},
	addInvoiceAddress: (data) => {
		return new Promise((resolve, reject) => {
			const invoiceData = {
				...data,
				tax_office: data.tax_office ? data.tax_office : config.undefinedTaxOffice,
				tax_number: data.tax_number ? data.tax_number : config.undefinedTaxNumber,
				id_number: (!data.id_number || data.id_number === "") ? config.undefinedTaxNumber : data.id_number,
				phone_number: data.mobile_number
			}

			request.post(
				`invoice`,
				invoiceData,
				function (payload, status, request) {
					if (status === 201 && payload?.invoice) {
						resolve(payload.invoice);
					}
					else {
						reject(request);
					}
				},
				{}
			);
		});
	},
	updateInvoiceAddress: (data, id) => {
		const invoiceData = {
			...data,
			tax_office: data.tax_office ? data.tax_office : config.undefinedTaxOffice,
			tax_number: data.tax_number ? data.tax_number : config.undefinedTaxNumber,
			id_number: (!data.id_number || data.id_number === "") ? config.undefinedTaxNumber : data.id_number,
			phone_number: data.mobile_number
		}

		return new Promise((resolve, reject) => {
			request.put(
				`invoice/${id}`,
				invoiceData,
				function (payload, status, request) {
					if (status === 200 && payload) {
						resolve(payload);
					}
					else {
						reject(request);
					}
				},
				{}
			);
		});
	},
	deleteInvoiceAddress: (id) => {
		return new Promise((resolve, reject) => {
			request.delete(
				`invoice/${id}`,
				false,
				function (payload, status, request) {
					if (status === 200) {
						resolve(payload);
					}
					else {
						reject(request);
					}
				},
				{}
			);
		});
	},
	// Gift Codes
	getGiftCodes: () => {
		return new Promise((resolve, reject) => {
			request.get(
				`member/promotions`,
				false,
				function (payload, status, request) {
					if (status === 200 && payload && payload.active && payload.past) {
						resolve(payload)
					}
					else {
						reject(request);
					}
				},
				{ });
		});
	},
	
}

export default userServices;