import config from '/config';

// Data
import listingFilters from '/data/listing-filters'

const parseRequestQuery = (query, pageType = 'category') => {
	let parsedQuery = {};

	for(const key in query) {
		const template = listingFilters[pageType][key];

		if(template) {
			if(template.dataType === 'array') {
				let val = query[key] ? query[key].split(config.listingFilterSeperator) : [];
				if(val.length) {
					parsedQuery[key] = val;
				}
			}
			else {
				if(query[key]) {
					parsedQuery[key] = query[key];
				}
			}
		}
		else {
			parsedQuery[key] = query[key];
		}
	}

	return parsedQuery;
}

export default parseRequestQuery