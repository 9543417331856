import defaultFilter from '/data/listing-filters/default-filter.js'

const categoryFilters = {
	categories: {
		...defaultFilter,
		type: 'link',
		title: 'Kategoriler',
		singleTitle: 'Kategori',
		search: true,
		searchText: 'Kategori ara',
		hideFromSum: true,
	},
	brands: {
		...defaultFilter,
		title: "Markalar",
		singleTitle: "Marka",
		search: true,
		searchText: 'Marka ara',
		sort: true,
		dataType: 'array',
	},
	genders: {
		...defaultFilter,
		type: 'gender',
		title: "Cinsiyet",
		singleTitle: "Cinsiyet",
		dataType: 'string',
		// hideFromSum: true,
	},
	gender_type: {
		...defaultFilter,
		type: 'gender',
		title: "Cinsiyet",
		singleTitle: "Cinsiyet",
		dataType: 'string',
		// hideFromSum: true,
	},
	age_range: {
		...defaultFilter,
		type: 'age',
		title: 'Yaş Aralığı',
		singleTitle: 'Yaş Aralığı',
		dataType: 'array',
	},
	colors: {
		...defaultFilter,
		type: 'color',
		title: "Renk",
		singleTitle: "Renk",
		dataType: 'array',
		// hideFromSum: true,
	},
	order: {
		...defaultFilter,
		type: 'hidden',
		title: 'Sıralama',
		singleTitle: 'Sıra',
		dataType: 'string',
		hideFromSum: true,
	},
	price_range: {
		...defaultFilter,
		type: 'range',
		title: 'Fiyat Aralığı',
		singleTitle: 'Fiyat Aralığı',
		dataType: 'range',
		minKey: 'min_price',
		minSingleTitle: 'En Düşük Fiyat',
		maxKey: 'max_price',
		maxSingleTitle: 'En Yükseks Fiyat',
		unit: ' TL',
	},
	properties: {
		...defaultFilter,
		type: 'grouped',
		title: 'Ürün Özellikleri',
		singleTitle: 'Özellik',
		search: true,
		searchText: 'Özelliklerde ara',
		dataType: 'array',
		reduce: true,
	},
	today_cargo: {
		...defaultFilter,
		type: 'default',
		title: "Aynı Gün Kargo",
		singleTitle: "Aynı Gün Kargo",
		dataType: 'bool',
		hide: true,
	},
	stock: {
		...defaultFilter,
		type: 'default',
		title: "Sadece Stoktakiler",
		singleTitle: "Sadece Stoktakiler",
		dataType: 'bool',
		hide: true,
	},
}

export default categoryFilters