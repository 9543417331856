import categoryFilters from '/data/listing-filters/types/category'
import searchFilters from '/data/listing-filters/types/search'
import brandFilters from '/data/listing-filters/types/brand'
import customListFilters from '/data/listing-filters/types/custom-list'

const listingFilters = {
	category: categoryFilters,
	search: searchFilters,
	brand: brandFilters,
	customList: customListFilters,
}

export default listingFilters;