// Functions
import rawFormatDate from '@wearetla/tla-essentials-tools/functions/format-date';

const formatDate = (rawDate, ...props) => {
  try {
    const parsedSlashDate = !props?.[1]?.parsed ? rawDate.match(/(?<day>\d{2})\/(?<month>\d{2})\/(?<year>\d{4})( (?<time>\d{2}:\d{2}))?/) : undefined;
    
    if(parsedSlashDate?.input && parsedSlashDate.input === parsedSlashDate[0]) {
      try {
        return rawFormatDate(`${[parsedSlashDate.groups.year, parsedSlashDate.groups.month, parsedSlashDate.groups.day].join('-')}T${parsedSlashDate.groups.time ?? '00:00'}:00`, ...props);
      }
      catch(e) {
        return null;
      }
    }
  }
  catch(e) {
    console.log('Date parse error:', e)
  }

  return rawFormatDate(rawDate, ...props);
}

export default formatDate;