// Data
import orderStatusCodes from '/data/order-status-codes'

const computeActiveProducts = (order) => {
	return order.products.reduce((products, product) => {
		let newQuantity = product.sale_product_quantity;

		const canceledProducts = order.cancelled_products ? order.cancelled_products.find(p => p.sale_product_id === product.sale_product_id) : false;

		const returnedProducts = order.returned_products ? order.returned_products.find(p => p.sale_product_id === product.sale_product_id) : false;

		if(canceledProducts) {
			newQuantity = newQuantity - canceledProducts.sale_product_quantity;
		}
		if(returnedProducts) {
			newQuantity = newQuantity - returnedProducts.sale_product_quantity;
		}
		// Kargolanan ürünleri listelemeye karar verdik.
		// if(order.cargoes && order.cargoes.length !== 0) {
		// 	for(const cargo of order.cargoes) {
		// 		const cargoProd = cargo.products.find(p => p.sale_product_id === product.sale_product_id);

		// 		if(cargoProd) {
		// 			newQuantity = newQuantity - cargoProd.sale_product_quantity;
		// 		}
		// 	}
		// }

		const productStock = order.stock ? order.stock.find(s => s.serial_id === product.serial_id) : false;

		if(newQuantity !== 0) {
			return [
				...products,
				{
					...product,
					sale_product_quantity: newQuantity,
					in_stock: productStock ? productStock.stock > 0 : false,
				}
			]
		}
		else {
			return products;
		}
	}, [])
}

const processOrderData = (order) => {
	return {
		...order,
		status_object: {
			...orderStatusCodes[order.status] ? orderStatusCodes[order.status] : {
				...orderStatusCodes['default'],
				text: order.status_text,
			},
			returnable: order.returnable,
			cancellable: order.cancellable,
		},
		active_products: computeActiveProducts(order),
		cancelled_products: order.cancelled_products ? order.cancelled_products.map(p => {
			const productStock = order.stock ? order.stock.find(s => s.serial_id === p.serial_id) : false;
			return {
				...p,
				in_stock: productStock ? productStock.stock > 0 : false,
			}
		}) : false,
		returned_products: order.returned_products ? order.returned_products.map(p => {
			const productStock = order.stock ? order.stock.find(s => s.serial_id === p.serial_id) : false;
			return {
				...p,
				in_stock: productStock ? productStock.stock > 0 : false,
			}
		}) : false,
	};
}

export default processOrderData;