import { useState, useMemo, createContext, useEffect, useCallback } from 'react';
import omit from 'lodash/omit';

// Functions
import generateInsetStyles from '/functions/generate-inset-styles';
import observeObject from '/functions/observe-object';

// Context
import { useModals } from '@wearetla/tla-essentials-tools/utilities/modals';
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state';
import { useStorage } from '@wearetla/tla-essentials-tools/utilities/storage';

export const ApplicationContext = createContext({
	isApplication: false,
	cameraAllowed: false,
	postMessage: () => {},
});

export const ApplicationProvider = ({ children, initialState }) => {
	const rawStorageData = useStorage();
	const { safeAreaInsets, setData } = useGlobalState();
	const [isApplication] = useState(initialState);
	const { openModal } = useModals();
	const [cameraAllowed, setCameraAllowed] = useState(null);

	const storageData = useMemo(() => {
		const storageData = omit(rawStorageData, ['setData']);

		return Object.keys(storageData).reduce((newStorageData, key) => {
			return {
				...newStorageData,
				...((key && storageData[key]) ? { [key]: storageData[key].toString() } : {}),
			}
		}, {});
	}, [rawStorageData]);

	const postMessage = useCallback(
		(type, message) => {
			if (isApplication) {
				window?.ReactNativeWebView?.postMessage?.(JSON.stringify({ type, content: message }));
			}
		},
		[isApplication]
	);

	const appCommEventHandler = (e) => {
		postMessage('log', JSON.stringify({ message: 'App event taken!', detail: e.detail }));
		switch (e?.detail?.action) {
			case 'setPushData':
				postMessage('pushDataDetail', e.detail?.pushToken);
				setData('pushId', e.detail?.pushToken ?? '');
				break;
			case 'setCameraAllowed':
				setCameraAllowed(e.detail?.cameraPermission);
				break;
			case 'setSafeAreaInsets':
				try {
					const parsedInsets = JSON.parse(e.detail?.safeAreaInsets ?? '{}');
					setData('safeAreaInsets', parsedInsets);
				} catch (e) {
					console.warn('Insets parsing error', e);
				}
				break;
			case 'message':
				openModal('alert', {
					title: 'Uygulama Mesajı',
					message: e.detail.message,
				});
				break;
		}
	};

	const trackLocalStorageChange = (method, key, value) => {
		if(typeof key === 'string') {
			if (method === 'setItem' && value) {
				postMessage('setLocalStorageKey', { key, value });
			}
			else if (method === 'removeItem') {
				postMessage('removeLocalStorageKey', { key });
			}
		}
	};

	useEffect(() => {
		postMessage('cookieStorageUpdate', storageData);
		// StorageUpdate Eventi Expo App İçin Gerekli
		postMessage('storageUpdate', storageData);
	}, [postMessage, storageData]);

	useEffect(() => {
		if (isApplication) {
			document.addEventListener('applicationComm', appCommEventHandler);

			// LocalStorage değişikliklerini takip etme
			if(window.localStorage) {
				observeObject(window.localStorage, ['setItem', 'removeItem'], trackLocalStorageChange);
			}

			// Eski uygulama üzerinden onesignal ID setleme.
			window.setOnesignalID = (newId) => {
				rawStorageData.setData('pushId', newId);
			};

			postMessage('webLoaded');

			window.onerror = function (message, sourcefile, lineno, colno) {
				postMessage(
					'error',
					message + ' - Source: ' + sourcefile + ' Line: ' + lineno + ':' + colno
				);
				return true;
			};
		}
	}, [isApplication]);

	useEffect(() => {
		try {
			const styleTag = document.querySelector('style[data-role="safe-area-insets"]');

			if (isApplication && safeAreaInsets) {
				const insetStyles = generateInsetStyles(safeAreaInsets);
				if (styleTag) {
					styleTag.innerHTML = insetStyles;
				} else {
					const newStyleTag = document.createElement('style');
					newStyleTag.setAttribute('data-role', 'safe-area-insets');
					newStyleTag.innerHTML = insetStyles;
					document.head.prepend(newStyleTag);
				}
			}
		} catch (e) {
			console.log('Safe area inset parsing error', e);
		}
	}, [isApplication, safeAreaInsets, postMessage]);

	const api = useMemo(
		() => ({
			isApplication,
			cameraAllowed,
			postMessage,
		}),
		[isApplication, cameraAllowed, postMessage]
	);

	return <ApplicationContext.Provider value={api}>{children}</ApplicationContext.Provider>;
};
