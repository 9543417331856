export const defaultState = {
	initialized: false,
	busy: false,
	summary: {
		"services": [],
		"price": {
			"original": 0,
			"original_str": "0,00",
			"whole": 0,
			"whole_str": "0",
			"fraction": 0,
			"fraction_str": "00",
			"currency": "TL",
			"currency_symbol": "\u20ba",
			"decimal_seperator": ",",
			"thousands_separator": "."
		},
		"basket_quantity": 0,
		"service_total": {
			"original": 0,
			"original_str": "0,00",
			"whole": 0,
			"whole_str": "0",
			"fraction": 0,
			"fraction_str": "00",
			"currency": "TL",
			"currency_symbol": "\u20ba",
			"decimal_seperator": ",",
			"thousands_separator": "."
		},
		"total_tax": {
			"original": 0,
			"original_str": "0,00",
			"whole": 0,
			"whole_str": "0",
			"fraction": 0,
			"fraction_str": "00",
			"currency": "TL",
			"currency_symbol": "\u20ba",
			"decimal_seperator": ",",
			"thousands_separator": "."
		},
		"promotion_code": null,
		"installment": null,
		"grand_total": {
			"original": 0,
			"original_str": "0,00",
			"whole": 0,
			"whole_str": "0",
			"fraction": 0,
			"fraction_str": "00",
			"currency": "TL",
			"currency_symbol": "\u20ba",
			"decimal_seperator": ",",
			"thousands_separator": "."
		},
		"price_taxless": {
			"original": 0,
			"original_str": "0,00",
			"whole": 0,
			"whole_str": "0",
			"fraction": 0,
			"fraction_str": "00",
			"currency": "TL",
			"currency_symbol": "\u20ba",
			"decimal_seperator": ",",
			"thousands_separator": "."
		},
		"given_cargo_day": null,
		"bagProduct": false,
		"deliveryProduct": false,
	},
	updating: false,
	products: [],
	indexedProducts: {},
};