import parseISO from 'date-fns/parseISO'
import differenceInMonths from 'date-fns/differenceInMonths'
import differenceInYears from 'date-fns/differenceInYears'

const birthdateToAge = (date, inMonths = false) => {
	const today = new Date();
	const conclusionFunct = inMonths ? differenceInMonths : differenceInYears;
	return conclusionFunct(today, parseISO(date));
}

export default birthdateToAge;