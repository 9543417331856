import loadable from '@loadable/component';

// Context
import { useNavigator } from '@wearetla/tla-essentials-tools/utilities/navigator';

const CheckoutProvider = loadable(() => import('./checkout-provider'));

export const ConditionalCheckoutProvider = ({ children }) => {
  const { activeRoute } = useNavigator();

  if(activeRoute?.key === 'checkout') {
    return <CheckoutProvider>
      {children}
    </CheckoutProvider>
  }

  return children;
}