import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Context
import { ChildrenContext } from './children-context'
import { useAuth } from '@wearetla/tla-essentials-tools/utilities/auth';

// Services
import userServices from '/services/user'

// Data
import defaultChildSelection from '/data/default-child-selection';
import { useStorage } from '@wearetla/tla-essentials-tools/utilities/storage';

export const ChildrenProvider = ({ children: childrenProp }) => {
	const { setData: setStorageData } = useStorage();
	
	const [children, setChildren] = useState([]);
	const [childSelection, setChildSelection] = useState(defaultChildSelection);
	const userChildrenData = useMemo(() => [], []);
	const { loggedIn } = useAuth();

	useEffect(() => {
		var storedData = defaultChildSelection;
		const storageChildSelection = (() => { try { return window.localStorage?.getItem('childSelection'); } catch(e) { return null } })();

		if(storageChildSelection) {
			try {
				storedData = JSON.parse(storageChildSelection);
			}
			catch(e) {
				storedData = defaultChildSelection;
			}

			if(!(storedData.empty !== undefined && storedData.id !== undefined && storedData.gender !== undefined && storedData.age !== undefined)) {
				window.localStorage?.removeItem('childSelection');
				storedData = defaultChildSelection;
			}
			else {
				setChildSelection(storedData);
			}
		}
	}, []);

	useEffect(() => {
		try {
			window.localStorage?.setItem('childSelection', JSON.stringify(childSelection));
			setStorageData('childSelectionAvailable', !childSelection.empty);
		}
		catch(e) {
			console.log('Children storage parse error:', e);
		}
	}, [childSelection])

	const getChildren = () => {
		return new Promise((resolve, reject) => {
			userServices.getChildren().then((data) => {
				setChildren(data);
				resolve(data);
			}).catch(reject)
		});
	}

	useEffect(() => {
		if(loggedIn) {
			getChildren();
		}
	}, [loggedIn])

	return <ChildrenContext.Provider value={{
		children,
		childData: children,
		getChildren,
		childSelection,
		setChildSelection: (selection) => { setChildSelection(selection || defaultChildSelection) },
		userChildrenData,
	}}>
		{childrenProp}
	</ChildrenContext.Provider>
}

ChildrenProvider.propTypes = {
	children: PropTypes.node,
}