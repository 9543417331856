import { useEffect } from 'react';
import { setHeaders } from '@wearetla/tla-essentials-tools/utilities/request';
import { useStorage } from '@wearetla/tla-essentials-tools/utilities/storage';
import config from '/config';
import { useBreakpoints } from '@wearetla/tla-essentials-tools/utilities/breakpoints';
import { useApplication } from '/utilities/application';
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state';

export const RequestHeadersController = () => {
	const { storeId, guestToken, authToken } = useStorage();
	const { mobile, isIos, isAndroid } = useBreakpoints();
	const { isApplication } = useApplication();
	const { pushId } = useGlobalState();

	useEffect(() => {
		setHeaders(config.defaultHeaders);
	}, [])

	useEffect(() => {
		setHeaders({
			'Push-Id': pushId,
		})
	}, [pushId])

	useEffect(() => {
		setHeaders({
			'store-id': storeId,
		})
	}, [storeId])

	useEffect(() => {
		setHeaders({
			'guest-token': guestToken,
		})
	}, [guestToken])

	useEffect(() => {
		setHeaders({
			'authorization': authToken ? `Bearer ${authToken}` : false,
		})
	}, [authToken])

	useEffect(() => {
		const deviceOs = `${isApplication ? 'mobile' : 'web'}_${isIos ? 'ios' : (isAndroid ? 'android' : 'computer')}`
		setHeaders({
			'Device-OS': deviceOs,
		})
	}, [isApplication, mobile, isIos, isAndroid])

	return null;
}