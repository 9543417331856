import { createContext } from 'react';

export const WishlistContext = createContext({
	favoriteBusy: true,
	alarmsBusy: true,
	favoriteProducts: {},
	alarmProducts: {
		stock: {},
		price: {},
	},
	fetchFavoriteProducts: () => {},
	fetchAlarmProducts: () => {},
	toggleFavorite: () => {},
	togglePriceAlarm: () => {},
	toggleStockAlarm: () => {},
})