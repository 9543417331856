import '/assets/styles/app.scss';
import config from '/config';
import useServerProps from '@wearetla/tla-essentials-tools/hooks/server-props';
import PropTypes from 'prop-types'
import loadable from '@loadable/component';

// Data
import routes from '/routes'
import layouts from '/views/layouts'
import validations from '/data/validation'

import { RequestHeadersController } from '/controllers/request-headers';
// import { ArviaController } from '/controllers/arvia';
import { GlobalStateController } from '/controllers/global-state';
// import { AssetVersionController } from '/controllers/asset-version';
import { AnalyticsProvider } from '/utilities/analytics';


// Context & Utils
import { ModalsProvider, ModalsRenderer } from '@wearetla/tla-essentials-tools/utilities/modals';
import { NavigatorProvider, NavigatorRoutes } from '@wearetla/tla-essentials-tools/utilities/navigator';
import { AuthProvider } from '/utilities/auth';
import { ApplicationProvider } from '/utilities/application';
import { BasketProvider } from '/controllers/basket';
import { WishlistProvider } from '/controllers/wishlist';
import { DeliveryProvider } from '/controllers/delivery';
import { ChildrenProvider } from '/utilities/children';
import { AppNotificationsProvider } from '/utilities/app-notifications';
import { FormsProvider } from '@wearetla/tla-essentials-tools/partials/forms';
import { StorageProvider } from '@wearetla/tla-essentials-tools/utilities/storage';
import { GlobalStateProvider, useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state';
import { BreakpointsProvider } from '@wearetla/tla-essentials-tools/utilities/breakpoints';
import { Messenger } from '@wearetla/tla-essentials-tools/utilities/messenger';
import { HeadProvider } from '@wearetla/tla-essentials-tools/utilities/head'
import { GlobalEventsProvider } from '@wearetla/tla-essentials-tools/utilities/global-events';
import { LazySentryProvider } from '/utilities/sentry';
import { SegmentifyController } from './utilities/segmentify';

// Partials
import Loader from '/views/partials/loader';
import RoundBtn from '/views/partials/round-btn';
import customInputTypes from '/views/partials/input-types';

// Modals
import modals from '/views/modals';
import { ConditionalCheckoutProvider } from './utilities/checkout';

// Lazy Components
const SentryDataController = loadable(() => import('./utilities/sentry/sentry-data-controller'), { ssr: false });
const CookieProAssets = loadable(() => import('./views/sections/cookie-pro'), { ssr: true });
// const CookieDisclaimer = loadable(() => import('./views/sections/cookie-disclaimer'), { ssr: false });
// const ScrollToTop = loadable(() => import('./views/partials/scroll-to-top'), { ssr: false });

const App = ({serverProps: ssrProps}) => {
	const serverProps = useServerProps(ssrProps);

	return (
		<LazySentryProvider serverProps={serverProps}>
			<ModalsProvider config={{
				layers: 2,
				styles: () => (
					import('/assets/styles/modals/modals.scss')
				),
				modals: modals,
				closeBtn: <RoundBtn className="modal-closebtn" />,
			}}>
				<BreakpointsProvider initialState={serverProps?.mediaStatus}>
						<GlobalEventsProvider>
							<GlobalStateProvider initialData={serverProps?.sharedData}>
								<StorageProvider initialCookies={serverProps?.cookies}>
									<ApplicationProvider initialState={serverProps?.isApplication}>
										<RequestHeadersController />
										<AuthProvider>
											<DeliveryProvider>
												<BasketProvider>
													<FormsProvider validations={validations} inputTypes={customInputTypes}>
														<NavigatorProvider
															serverProps={serverProps}
															pageLoader={<Loader message="Hesap bilgileriniz yükleniyor..." />}
															routes={routes}
															layouts={layouts}>
															{/* <ArviaController /> */}
															<HeadProvider serverProps={serverProps}>
																<ChildrenProvider>
																	<AnalyticsProvider>
																		<WishlistProvider>
																			<AppNotificationsProvider>
																				<ConditionalCheckoutProvider>
																					<ModalsRenderer />
																					{/* <AssetVersionController /> */}
																					<GlobalStateController />
																					<SegmentifyController />
																					<Messenger />	
																						<SiteWrap>
																							<NavigatorRoutes />
																							<SentryDataController />
																							{/* <CookieDisclaimer /> */}
																						</SiteWrap>
																						{/* <ScrollToTop /> */}
																						{!serverProps?.isApplication && config.cookiePro?.enabled &&
																							<CookieProAssets />
																						}
																				</ConditionalCheckoutProvider>
																			</AppNotificationsProvider>
																		</WishlistProvider>
																	</AnalyticsProvider>
																</ChildrenProvider>
															</HeadProvider>
														</NavigatorProvider>
													</FormsProvider>
												</BasketProvider>
											</DeliveryProvider>
										</AuthProvider>
									</ApplicationProvider>
								</StorageProvider>
							</GlobalStateProvider>
						</GlobalEventsProvider>
				</BreakpointsProvider>
			</ModalsProvider>
		</LazySentryProvider>
	)
}

App.propTypes = {
	serverProps: PropTypes.object
}

const SiteWrap = ({ children }) => {
	const { isApplication } = useGlobalState();

	return (
		<div id="site-wrap" className={isApplication ? 'app' : undefined}>
			{children}
		</div>
	);
}

SiteWrap.propTypes = {
	children: PropTypes.node,
}

export default App;