import loadable from '@loadable/component'

const inputs = {
	radio: loadable(() => import('./radio')),
	checkbox: loadable(() => import('./checkbox')),
	textarea: loadable(() => import('./textarea')),
	text: loadable(() => import('./text')),
	phone: loadable(() => import('./text')),
	email: loadable(() => import('./text')),
	number: loadable(() => import('./text')),
	password: loadable(() => import('./text')),
	date: loadable(() => import('./date')),
	select: loadable(() => import('./select')),
}

export default inputs;