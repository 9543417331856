import config from '/config';
import request from '@wearetla/tla-essentials-tools/utilities/request';
import keyBy from 'lodash/keyBy'
import kebabCase from 'lodash/kebabCase'

// Functions
import formatDate from '/functions/format-date';

// Services
import productServices from '/services/product';

const trimSubCategory = (category, level = 1) => {
	return {
		name: category.name,
		slug: category.slug,
		id: category.id,
		banners: category.banners,
		brands: category.brands,
		list_image: category.list_image,
		children: (category.children ?? []).map(c => trimSubCategory(c, level +1)),
	}
}

const siteServices = {
	checkAppUpdates: () => {
		return new Promise((resolve, reject) => {
			request.get('get-build-number', false, (payload) => {
				if(payload?.length) {
					resolve(config.buildNumber !== payload);
				}
				else {
					reject(payload);
				}
			}, { static: true, external: true });
		});
	},
	// Categories
	getCategories: () => {
		return new Promise((resolve, reject) => {
			request.get('categories/menu', {}, (payload, status, request) => {
				if (payload && payload.menu) {
					resolve(payload.menu);
				}
				else {
					reject(request);
				}
			}, { showMessages: false });
		});
	},
	getRootCategories: () => {
		return new Promise((resolve, reject) => {
			request.get('categories/menu', {}, (payload, status, request) => {
				if (payload && payload.menu) {
					resolve(payload.menu.map(trimSubCategory));
				}
				else {
					reject(request);
				}
			}, { showMessages: false });
		});
	},
	// Showcases & Banners
	getBanners: (bannerID) => {
		return new Promise((resolve, reject) => {
			request.get(`banners/${bannerID}`, {}, (payload, status, request) => {
				if (payload && payload.banners) {
					resolve(payload.banners);
				}
				else {
					reject(request);
				}
			}, { showMessages: false });
		});
	},
	getShowcase: (showcaseID) => {
		return new Promise((resolve, reject) => {
			request.get(`showcases/${showcaseID}`, {}, (payload, status, request) => {
				if (payload && payload.showcase) {
					resolve(payload.showcase);
				}
				else {
					reject(request);
				}
			}, { showMessages: false });
		});
	},
	getCategoryTopProducts: (categoryID) => {
		return new Promise((resolve, reject) => {
			request.get(`category-top-products/${categoryID}`, {}, (payload, status, request) => {
				if (payload && payload.products) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			}, { showMessages: false });
		});
	},
	getProductCategoryTopProducts: (productID, ctx) => {
		return new Promise((resolve, reject) => {
			request.get(`showcases/best-sellers/${productID}`, {}, (payload, status, request) => {
				if (payload && payload.showcase) {
					resolve({
						...payload.showcase,
						productID: productID,
					});
				}
				else {
					reject(request);
				}
			}, { showMessages: false, cache: true, ctx: ctx });
		});
	},
	getProductCategoryNewProducts: (productID, ctx) => {
		return new Promise((resolve, reject) => {
			request.get(`showcases/new-products/${productID}`, {}, (payload, status, request) => {
				if (payload && payload.showcase) {
					resolve({
						...payload.showcase,
						productID: productID,
					});
				}
				else {
					reject(request);
				}
			}, { showMessages: false, cache: true, ctx: ctx });
		});
	},
	getLastVisitedProducts: (excludeBarcode) => {
		return new Promise((resolve, reject) => {
			request.get(`last-visit`, false, (payload, status, request) => {
				if(status === 200 && payload && payload.products) {
					resolve(excludeBarcode ? payload.products.filter(p => p.barcode !== excludeBarcode) : payload.products);
				}
				else {
					reject(request.data.meta ? request.data.meta.messages : 'Son gezdiğiniz ürünler getirilirken gönderilirken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
				}
			})
		})
	},
	getPopups: () => {
		return new Promise((resolve, reject) => {
			request.get(`campaigns/pop-up`, false, (payload, status, request) => {
				if(status === 200 && payload && payload.campaign_popup) {
					resolve(payload.campaign_popup);
				}
				else {
					reject(request);
				}
			}, { showMessages: false })
		})
	},
	getFeaturedCategories: () => {
		// api/categories/featured
		return new Promise((resolve, reject) => {
			request.get(`categories/featured`, false, (payload, status, request) => {
				if (status === 200 && payload && payload.menu) {
					resolve(payload.menu.map(item => ({
						id: item.id,
						title: item.title,
						list_image: item.list_image,
						name: item.name,
						slug: item.slug,
					})))
				}
				else {
					reject(request);
				}

			}, { exceptionOnError: true, showMessages: false });
		});
	},
	// SEO
	getSchema: (type, id) => {
		return new Promise((resolve, reject) => {
			request.get(`snippet/${id}/${type}`, false, (payload, status, request) => {
				if(status === 200 && payload) {
					resolve(payload.snippet ? payload.snippet : false);
				}
				else {
					reject(request);
				}
			})
		})
	},
	checkUrlRedirects: (url) => {
		return new Promise((resolve, reject) => {
			request.post(`sys/check-url`, { url }, (payload, status, request) => {
				if(status === 200) {
					resolve(payload.url?.length ? payload.url[0] : false);
				}
				else {
					reject(request);
				}
			})
		})
	},
	// Faq & Corporate
	getFaqs: () => {
		return new Promise((resolve, reject) => {
			request.get(`faqs`, false, (payload, status, request) => {
				if (status === 200 && payload && payload.faqs) {
					resolve(payload.faqs)
				}
				else {
					reject(request);
				}

			}, { exceptionOnError: true, showMessages: false });
		});
	},
	getFaq: (id) => {
		return new Promise((resolve, reject) => {
			request.get(`faqs/${id}`, false, (payload, status, request) => {
				if (status === 200 && payload && payload.faq) {
					resolve(payload.faq)
				}
				else {
					reject(request);
				}

			}, { exceptionOnError: true, showMessages: false });
		});
	},
	searchFaqs: (searchQuery) => {
		return new Promise((resolve, reject) => {
			request.get(`faqs/search`, { search_query: searchQuery }, (payload, status, request) => {
				if (status === 200 && payload && payload.faq) {
					resolve({
						searchQuery: searchQuery,
						faqs: payload.faq
					})
				}
				else {
					reject(request);
				}

			}, { exceptionOnError: true, showMessages: false });
		});
	},
	// Stores
	getStores: () => {
		return new Promise((resolve, reject) => {
			request.get(`store/list`, false, (payload, status, request) => {
				if (status === 200 && payload && payload.stores) {
					resolve(payload.stores.map((store) => ({
						...store,
						slug: store.slug ? store.slug : kebabCase(store.branch_name),
					})))
				}
				else {
					reject(request);
				}

			}, { cache: true });
		});
	},
	getStore: (storeID) => {
		return new Promise((resolve, reject) => {
			request.get(`store/detail/${storeID}`, false, (payload, status, request) => {
				if (status === 200 && payload && payload.store) {
					resolve({
						...payload.store,
						slug: payload.store.slug ? payload.store.slug : kebabCase(payload.store.branch_name),
					})
				}
				else {
					reject(request);
				}

			}, { });
		});
	},
	// Cities & Districts
	getCities: () => {
		return new Promise((resolve, reject) => {
			request.get(`sys/cities`, false, (payload, status, request) => {
				if (status === 200 && payload && payload.cities) {
					const parsedCities = payload.cities.sort((a, b) => (a.rank - b.rank)).map(city => ({
						...city,
						value: city.id,
						label: city.name,
					}));
					resolve(parsedCities)
				}
				else {
					reject(request);
				}

			});
		});
	},
	getDistricts: (cityID) => {
		return new Promise((resolve, reject) => {
			request.get(`sys/cities/${cityID}`, false, (payload, status, request) => {
				if (status === 200 && payload && payload.districts) {
					const parsedDistricts = payload.districts.map(district => ({
						...district,
						value: district.id,
						label: district.name,
					}))
					resolve(parsedDistricts)
				}
				else {
					reject(request);
				}

			});
		});
	},
	getQuarters: (districtID) => {
		return new Promise((resolve, reject) => {
			request.get(`sys/quarters/${districtID}`, false, (payload, status, request) => {
				if (status === 200 && payload && payload.quarters) {
					const [parsedQuarters] = payload.quarters.reduce(([parsedQuarters, availableValues], quarter) => {
						if(availableValues.includes(quarter.name)) {
							return [parsedQuarters, availableValues];
						}

						return [
							[
								...parsedQuarters,
								{
									...quarter,
									value: quarter.id,
									label: quarter.name,
								}
							],
							[...availableValues, quarter.name]
						]
					}, [[], []]);
					resolve(parsedQuarters)
				}
				else {
					reject(request);
				}

			});
		});
	},
	// Brands
	getFeaturedBrands: (limit = false) => {
		return new Promise((resolve, reject) => {
			request.get('brands', { featured: 1 }, (payload, status, request) => {
				if (payload && payload.brands) {
					resolve(limit ? payload.brands.slice(0, 30) : payload.brands);
				}
				else {
					reject(request);
				}
			}, { showMessages: false });
		});
	},
	getFooterBrands: () => {
		return new Promise((resolve, reject) => {
			request.get('brand/sections/footer', { }, (payload, status, request) => {
				if (payload && payload.brands) {
					resolve(payload.brands);
				}
				else {
					reject(request);
				}
			}, { showMessages: false });
		});
	},
	getFooterProducts: () => {
		return new Promise((resolve, reject) => {
			return Promise.all([
				productServices.getCustomListProducts(1616, { }),
				productServices.getCustomListProducts(1615, { }),
				productServices.getCustomListProducts(1617, { }),
			]).then((responses) => {
				resolve(responses.map(payload => (
					{
						id: payload.listData?.id,
						title: payload.listData?.title,
						slug: payload.listData?.url,
						products: payload.products.map((product) => ({
							id: product.id,
							slug: product.slug,
							title: product.information,
						})),
					}
				)));
			}).catch(() => {
				reject();
			});
		})
	},
	getAllBrands: () => {
		return new Promise((resolve, reject) => {
			request.get('brands', {}, (payload, status, request) => {
				if (payload && payload.brands) {
					let groups = {};

					for(let brand of payload.brands) {
						let key = brand.name[0];
						if(!isNaN(key)) {
							key = '0'
						}

						if(groups[key]) {
							groups[key].push(brand);
						}
						else {
							groups[key] = [brand];
						}
					}

					let sortedBrands = Object.keys(groups).map(key => ({
						key: key,
						brands: groups[key]
					})).sort((a, b) => {
						var atitle = a.key;
						var btitle = b.key;
						var alphabet = "AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789";
						if (atitle.length === 0 || btitle.length === 0) {
							return atitle.length - btitle.length;
						}
						for(var i=0;i<atitle.length && i<btitle.length;i++){
							var ai = alphabet.indexOf(atitle[i]);
							var bi = alphabet.indexOf(btitle[i]);
							if (ai !== bi) {
								return ai - bi;
							}
						}
						return 0;
					});
			
					resolve(sortedBrands);
				}
				else {
					reject(request);
				}
			}, { showMessages: false });
		});
	},
	// Campaigns
	getCampaigns: () => {
		return new Promise((resolve, reject) => {
			request.get(`campaigns/contents`, false, (payload, status, request) => {
				if (status === 200 && payload && payload.campaigns) {
					resolve(payload.campaigns.map(c => ({
						...c,
						slug: kebabCase(c.title),
					})))
				}
				else {
					reject(status);
				}
			}, { });
		});
	},
	getCampaign: (campaignID) => {
		return new Promise((resolve, reject) => {
			request.get(`campaigns/contents`, false, (payload, status, request) => {
				if (status === 200 && payload && payload.campaigns) {
					const foundCampaign = payload.campaigns.find(c => c.id.toString() === campaignID);

					if(foundCampaign) {
						resolve({
							...foundCampaign,
							slug: kebabCase(foundCampaign.title),
						});
					}
					else {
						reject(404);
					}
				}
				else {
					reject(status);
				}
			}, { });
		});
	},
	// Feedback
	sendSaleFeedback: (saleID, saleCode, saleScore) => {
		return new Promise((resolve, reject) => {
			request.post(`feedback`, { sale_id: saleID, sale_code: saleCode, sale_score: saleScore }, (payload, status, request) => {
				if(status === 200) {
					resolve(true);
				}
				else {
					reject(request);
				}
			}, { showMessages: false });
		});
	},
	// Sys
	getInitialSysParameters: () => {
		return new Promise((resolve, reject) => {
			request.get(`sys/parameters/bundle`, {}, (payload, status, request) => {
				if (payload && payload.parameters) {
					const parsedParams = keyBy(payload.parameters, 'name');
					resolve(parsedParams);
				}
				else {
					reject(request);
				}
			}, { showMessages: false });
		});
	},
	getSysParameter: (paramKey) => {
		return new Promise((resolve, reject) => {
			request.get(`sys/parameters/content/${paramKey}`, {}, (payload, status, request) => {
				if (payload && payload.content) {
					resolve(payload.content);
				}
				else {
					reject(request);
				}
			}, { showMessages: false });
		});
	},
	getMessageCategories: () => {
		return new Promise((resolve, reject) => {
			request.get(`member/messages/categories`, {}, (payload, status, request) => {
				if (payload && payload.categories) {
					resolve(payload.categories);
				}
				else {
					reject(request);
				}
			}, { showMessages: false });
		});
	},
	getMessageSubjects: () => {
		return new Promise((resolve, reject) => {
			request.get(`member/messages/subjects`, {}, (payload, status, request) => {
				if (payload && payload.subjects) {
					resolve(payload.subjects);
				}
				else {
					reject(request);
				}
			}, { showMessages: false });
		});
	},
	getNotifications: () => {
		return new Promise((resolve, reject) => {
			request.get(`notifications`, {}, (payload, status, request) => {
				if (payload && payload.notifications) {
					resolve(payload.notifications.map((notification) => ({
						...notification,
						formatted_created_at: formatDate(notification.created_at),
					})));
				}
				else {
					reject(request);
				}
			}, { showMessages: false });
		});
	},
	// Static Data
	getBabyNames: () => {
		return new Promise((resolve, reject) => {
			request.get(`data/baby-names.json`, {}, (payload, status, request) => {
				if(request.data && request.data.length > 0) {
					resolve(request.data);
				}
				else {
					reject(false);
				}
			}, { static: true, showMessages: false });
		});
	},
	getPopularBabyBoyNames: () => {
		return new Promise((resolve, reject) => {
			request.get(`data/popular-baby-boy-names.json`, {}, (payload, status, request) => {
				if(request.data) {
					resolve(request.data);
				}
				else {
					reject(false);
				}
			}, { static: true, showMessages: false });
		});
	},
	getPopularBabyGirlNames: () => {
		return new Promise((resolve, reject) => {
			request.get(`data/popular-baby-girl-names.json`, {}, (payload, status, request) => {
				if(request.data) {
					resolve(request.data);
				}
				else {
					reject(request);
				}
			}, { static: true, showMessages: false });
		});
	},

}

export default siteServices;