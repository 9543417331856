import '/assets/styles/partials/round-btn.scss';

import Icon from "@wearetla/tla-essentials-tools/partials/icon";

const RoundBtn = ({className = '', icon = 'close', ...props}) => {
	return (
		<button
			className={`round-btn ${className}`}
			type="button"
			{...props}>
			<Icon name={icon} />
		</button>
	)
}

export default RoundBtn;