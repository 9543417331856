import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types'
import { setHeaders } from '@wearetla/tla-essentials-tools/utilities/request'

// Context
import { AuthContext } from '@wearetla/tla-essentials-tools/utilities/auth';
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state';
import { useStorage } from '@wearetla/tla-essentials-tools/utilities/storage';
import { useModals } from '@wearetla/tla-essentials-tools/utilities/modals';

// Functions
import formatPhone from '@wearetla/tla-essentials-tools/functions/format-phone'

// Services
import userServices from '/services/user';

export const AuthProvider = ({ children }) => {
	const { authToken, guestToken, setData } = useStorage();
	const [initialized, setInitialized] = useState(false);
	const [rawUserData, setUserDataRaw] = useState(null);
	const loggedIn = useMemo(() => (rawUserData && [1, 99].includes(rawUserData.status)), [rawUserData]);
	const isGuest = useMemo(() => loggedIn && rawUserData?.status === 99, [rawUserData, loggedIn]);

	const userData = useMemo(() => rawUserData ? {
		...rawUserData,
		mobile_number_formatted: formatPhone(rawUserData.mobile_number, true),
		guestUser: rawUserData.status === 99,
	} : null, [rawUserData])
	
	const { setError } = useGlobalState();
	const { openModal } = useModals();

	const setUserData = (userData) => {
		if(userData) {
			setUserDataRaw(userData);
		}
		else {
			setUserDataRaw(null)
		}
	}

	const softLogin = (token) => {
		return new Promise((resolve, reject) => {
			setHeaders({
				'authorization': `Bearer ${token}`,
			});
			userServices.getUserData().then((userData) => {
				setUserData(userData);
				setInitialized(true);
				setData('authToken', token);
				resolve(userData);
			}).catch((e) => {
				setHeaders({
					'authorization': false,
				});
				console.warn('User initialization error:', e);
				reject(e);
			})
		});
	}

	const getUserData = () => {
		return new Promise((resolve, reject) => {
			userServices.getUserData().then((userData) => {
				setUserData(userData);
				resolve(userData);
			}).catch((e) => {
				reject(e);
			})
		})
	}

	const hardLogout = () => {
		userServices.logout().then(() => {}).catch(() => {});
		setData('authToken', false);
		setData('guestToken', false);
	}

	const logout = () => {
		return new Promise((resolve) => {
			openModal('confirm', {
				message: 'Çıkış yapmak istediğinizden emin misiniz?',
				onConfirm: () => {
					hardLogout();
					resolve();
				}
			});
		})
	}

	const getUserStorageData = () => {
		return new Promise((resolve) => {
			console.warn('TODO: (?) v2 user services getUserStorageData buraya entegre edilecek');
			resolve();
		})
	}

	useEffect(() => {
		if(!guestToken) {
			userServices.getGuestToken().then((newGuestToken) => {
				setData('guestToken', newGuestToken)
			}).catch((e) => {
				console.warn('Guest-token initialization error:', e);
				setError();
			})
		}
	}, [guestToken])

	useEffect(() => {
		if(!authToken) {
			setHeaders({
				'authorization': false,
			});

			if(loggedIn) {
				setUserData(false);
			}
			else {
				setInitialized(true);
				setUserData(false);
			}
		}
		else if(!loggedIn && guestToken && authToken) {
			getUserData().then(() => {
				setInitialized(true);
			}).catch((e) => {
				setData('authToken', false);
				setData('guestToken', false);
				console.warn('User auth error:', e);
			})
		}
	}, [authToken, guestToken, loggedIn])

	return (
		<AuthContext.Provider
			value={{
				initialized,
				loggedIn,
				userData,
				isGuest,
				getUserData,
				getUserStorageData,
				logout,
				softLogin,
				setUserData,
			}}>
			{children}
		</AuthContext.Provider>
	)
}

AuthProvider.propTypes = {
	children: PropTypes.node,
}