import { useEffect, useState } from 'react';
import { DeliveryContext } from './delivery-context';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import config from '/config';

// Context
import { useStorage } from '@wearetla/tla-essentials-tools/utilities/storage';
import { useAuth } from '@wearetla/tla-essentials-tools/utilities/auth';

// Services
import userServices from '/services/user'
import storeServices from '/services/store'

const ADDRESS_TRIM_FIELDS = ['phone_number', 'mobile_number', 'recipient_name', 'alternative_recipient_name', 'default_profile', 'quarter_id', 'member_id'];

const getDeliveryData = () => {
	try {
		const data = window.localStorage?.getItem(config.storageKeys.deliverySelection);
		const decodedData = data ? JSON.parse(data) : false;
		if(
			decodedData &&
			typeof decodedData.storeId === 'number' &&
			// typeof decodedData.address?.city_id === 'number' &&
			// typeof decodedData.address?.city_name === 'string' &&
			// typeof decodedData.address?.district_id === 'number' &&
			// typeof decodedData.address?.district_name === 'string' &&
			typeof decodedData.address?.address === 'string' &&
			typeof decodedData.address?.building_number === 'string' &&
			typeof decodedData.address?.door_number === 'string' &&
			typeof decodedData.address?.floor === 'string' &&
			typeof decodedData.address?.for_directions === 'string' &&
			typeof decodedData.address?.name === 'string' &&
			typeof decodedData.address?.latitude === 'number' &&
			typeof decodedData.address?.longitude === 'number'
		) {
			return decodedData;
		}
		else {
			if(decodedData) {
				console.warn('Invalid delivery storage data. Cleaning: ', decodedData);
				window.localStorage?.removeItem(config.storageKeys.deliverySelection);
			}
			return false;
		}
	}
	catch(e) {
		console.error('Delivery parse error: ', e);
		return false;
	}
}

export const DeliveryProvider = ({ children }) => {
	const { loggedIn, initialized: userInitialized, userData } = useAuth();
	const { setData } = useStorage()

	const [initialized, setInitialized] = useState(false);
	const [selectedDelivery, setSelectedDelivery] = useState(false);
	const [addresses, setAddresses] = useState(false);

	const setDeliveryStorageDataFromStorage = (deliveryStorageData) => {
		const promises = [
			storeServices.getStore(deliveryStorageData.storeId)
		];
		
		if(deliveryStorageData.address.id) {
			promises.push(userServices.getAddress(deliveryStorageData.address.id));
		}

		Promise.all(promises).then(([store, fetchedAddress]) => {
			if(deliveryStorageData.address.id && !fetchedAddress) {
				setSelectedDelivery(false);
			}
			else {
				const fetchedDeliveryAddress = {
					...deliveryStorageData.address,
					...(fetchedAddress ? omit(fetchedAddress, ADDRESS_TRIM_FIELDS) : {}),
				};

				setSelectedDelivery({
					address: fetchedDeliveryAddress,
					store: store,
				})
			}
		}).catch(() => {
			console.warn(`Address #${deliveryStorageData.address.id} not found, storage data will be removed.`);
			if(deliveryStorageData.fromPersistence) {
				window.localStorage?.removeItem(config.storageKeys.deliverySelectionUserAddress);
			}
			setSelectedDelivery(false);
		}).finally(() => {
			setInitialized(true);
		})
	}

	useEffect(() => {
		const deliveryStorageData = getDeliveryData();
		if(deliveryStorageData) {
			setDeliveryStorageDataFromStorage(deliveryStorageData);
		}
		else {
			setSelectedDelivery(false);
			setInitialized(true);
		}
	}, [])

	useEffect(() => {
		if(initialized) {
			if(selectedDelivery?.address && selectedDelivery.store?.id) {
				if(selectedDelivery?.address.id && selectedDelivery.store.id) {
					window.localStorage?.setItem(config.storageKeys.deliverySelectionUserAddress, JSON.stringify({
						addressId: selectedDelivery.address.id,
						storeId: selectedDelivery.store.id
					}));
				}
				window.localStorage?.setItem(config.storageKeys.deliverySelection, JSON.stringify({
					address: selectedDelivery.address,
					storeId: selectedDelivery.store.id
				}));
				setData('storeId', selectedDelivery.store.id);
			}
			else {
				window.localStorage?.removeItem(config.storageKeys.deliverySelection);
				setData('storeId', config.defaultStorageValues.storeId);
			}
		}
	}, [selectedDelivery, initialized])

	useEffect(() => {
		if(loggedIn && !selectedDelivery && initialized) {
			var userStorageData = {};
			try {
				userStorageData = JSON.parse(window.localStorage?.getItem(config.storageKeys.deliverySelectionUserAddress) ?? '{}');
			}
			catch(e) {
				console.warn('Invalid user storage data.');
			}

			if(userStorageData?.addressId && userStorageData?.storeId) {
				setInitialized(false);
				setDeliveryStorageDataFromStorage({
					address: { id: userStorageData.addressId },
					storeId: userStorageData.storeId,
					fromPersistence: true,
				});
			}
		}
	}, [initialized, selectedDelivery, loggedIn])

	useEffect(() => {
		if(!loggedIn) {
			setAddresses(false);
			if(userInitialized && selectedDelivery?.address?.id) {
				setSelectedDelivery(false);
			}
		}
	}, [loggedIn, userInitialized, selectedDelivery])

	const getAddresses = (force) => {
		return new Promise((resolve, reject) => {
			if(addresses && !force) {
				resolve(addresses);
			}
			else {
				userServices.listAddresses().then(fetchedAddresses => {
					setAddresses(fetchedAddresses);
					resolve(fetchedAddresses);
				}).catch((feedback) => {
					reject(feedback);
				})
			}
		})
	}

	const selectAddress = ((addressInfo, store) => {
		setSelectedDelivery({
			address: {
				...omit(addressInfo, ADDRESS_TRIM_FIELDS),
				latitude: parseFloat(addressInfo.latitude),
				longitude: parseFloat(addressInfo.longitude),
			},
			store: store,
		});
	})

	const saveAddress = ((addressInfoParam, save = true) => {
		return new Promise((resolve, reject) => {
			const { store, ...addressInfo } = addressInfoParam;

			const promise = store ?
			(() => ( new Promise((resolve) => { resolve({store}); })))() :
			storeServices.locationLookup(addressInfo.latitude, addressInfo.longitude);

			promise.then(({store}) => {
				if(store) {
					if(loggedIn) {
						const composedAddress = {
							...addressInfo,
							latitude: addressInfo.latitude.toString(),
							longitude: addressInfo.longitude.toString(),
							mobile_number: userData.mobile_number,
							phone_number: userData.mobile_number,
							recipient_name: userData.full_name,
							alternative_recipient_name: null,
							quarter_id: null,
							city_id: 0,
							city_name: '',
							district_id: 0,
							district_name: '',
						}

						if(save) {
							const addressPromise = addressInfo.id ? userServices.updateAddress(addressInfo.id, composedAddress) : userServices.saveAddress(composedAddress);
	
							addressPromise.then(savedAddress => {
								getAddresses(true).then(() => {
									selectAddress(savedAddress, store);
									resolve(savedAddress);
								}).catch(feedback => {
									reject(feedback);
								})
							}).catch(feedback => {
								reject(feedback);
							})
						}
						else {
							selectAddress(composedAddress, store);
							resolve(composedAddress);
						}
					}
					else {
						setSelectedDelivery({
							address: addressInfo,
							store: store,
						});
						resolve(true);
					}
				}
				else {
					reject('Seçtiğiniz adresin konumuna karşılık gelen bir mağaza bulunamadı.');
				}
			}).catch((feedback) => {
				reject(feedback);
			})
		})
	});

	const deleteAddress = (addressID) => {
		return new Promise((resolve, reject) => {
			userServices.deleteAddress(addressID).then(() => {
				if(addressID === selectedDelivery?.address?.id) {
					setSelectedDelivery(false);
				}
				getAddresses(true).then((addresses) => {
					resolve(addresses);
				}).catch((feedback) => {
					reject(feedback);
				})
			});
		})
	}

	return <DeliveryContext.Provider value={{
		initialized,
		selectedDelivery,
		addresses,
		saveAddress,
		getAddresses,
		deleteAddress,
	}}>
		{children}
	</DeliveryContext.Provider>
}

DeliveryProvider.propTypes = {
	children: PropTypes.node,
}