import { useCallback, useEffect, useState, useRef } from 'react';
import { WishlistContext } from './wishlist-context';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';

// Services
import userServices from '/services/user';

// Context
import { useAuth } from '@wearetla/tla-essentials-tools/utilities/auth';
import { useModals } from '@wearetla/tla-essentials-tools/utilities/modals';
import { useAnalytics, parseAnalyticsProduct } from '/utilities/analytics';

// Services
// import userServices from '/services/user'


export const WishlistProvider = ({ children }) => {
	const { loggedIn } = useAuth();
	const { openModal } = useModals();
	const { sendAnalyticsEvent } = useAnalytics();

	const [favoriteBusy, setFavoriteBusy] = useState(true);
	const [alarmsBusy, setAlarmsBusy] = useState(true);

	const alarmProductsRef = useRef(null);
	const [alarmProducts, setAlarmProductsRaw] = useState(alarmProductsRef.current);

	const setAlarmProducts = (products) => {
		alarmProductsRef.current = products;
		setAlarmProductsRaw(alarmProductsRef.current);
	}

	const favoriteProductsRef = useRef(null);
	const [favoriteProducts, setFavoriteProductsRaw] = useState(favoriteProductsRef.current);
	
	const setFavoriteProducts = (products) => {
		favoriteProductsRef.current = products;
		setFavoriteProductsRaw(favoriteProductsRef.current);
	}

	const fetchAlarmProducts = useCallback(() => {
		return new Promise((resolve, reject) => {
			if(loggedIn) {
				userServices.getAlarmProducts().then((payload) => {
					setAlarmProducts({
						price: payload.price.reduce((products, product) => ({
							...products,
							[product.serial_id]: product,
						}), {}),
						stock: payload.stock.reduce((products, product) => ({
							...products,
							[product.serial_id]: product,
						}), {})
					});
					setAlarmsBusy(false);
					resolve(payload);
					
				}).catch((e) => {
					setAlarmsBusy(false);
					reject(false);
				})
			}
			else {
				setAlarmsBusy(false);
				setFavoriteProducts(null);
				resolve({
					price: {},
					stock: {},
				})
			}
		})
	}, [loggedIn]);

	const fetchFavoriteProducts = useCallback(() => {
		return new Promise((resolve, reject) => {
			if(loggedIn) {
				userServices.getFavoriteProducts().then((payload) => {

					setFavoriteProducts(payload.reduce((products, product) => ({
						...products,
						[product.serial_id]: product,
					}), {}));
					setFavoriteBusy(false);
					resolve(payload);
					
				}).catch(() => {
					setFavoriteBusy(false);
					reject(false);
				})
			}
			else {
				setFavoriteBusy(false);
				setFavoriteProducts(null);
				resolve({})
			}
		})
	}, [loggedIn]);

	const toggleFavorite = useCallback((product) => {
		if(loggedIn) {
			setFavoriteBusy(true);
			if(favoriteProducts[product.serial_id]) {
				userServices.removeFavoriteProduct(favoriteProducts[product.serial_id]).then(() => {
					setFavoriteProducts(omit(favoriteProductsRef.current, [product.serial_id]))
				}).catch(() => {
				}).finally(() => {
					setFavoriteBusy(false);
				})
			}
			else {
				userServices.addFavoriteProduct(product).then((payload) => {
					setFavoriteProducts({
						...favoriteProductsRef.current,
						[product.serial_id]: {
							...product,
							item_id: payload.item_id,
						}
					});

					sendAnalyticsEvent({
						event: 'add_to_wishlist',
						value: product.price.original,
						products: [parseAnalyticsProduct({
							...product,
						}, {
							quantity: 1,
						})],
					});
				}).catch(() => {
				}).finally(() => {
					setFavoriteBusy(false);
				})
			}
		}
		else {
			openModal('login-message', { message: 'Bu ürünü favorilerinize eklemek için üye girişi yapmalısınız.' })
		}

	}, [favoriteProducts, loggedIn])

	const removeFavorite = useCallback((product) => {
		return new Promise((resolve, reject) => {
			if(loggedIn) {
				setFavoriteBusy(true);
				if(favoriteProductsRef.current[product.serial_id]) {
					userServices.removeFavoriteProduct(favoriteProductsRef.current[product.serial_id]).then(() => {
						setFavoriteProducts(omit(favoriteProductsRef.current, [product.serial_id]))
						resolve(product);
					}).catch((e) => {
						reject();
					}).finally(() => {
						setFavoriteBusy(false);
					})
				}
				else {
					reject();
				}
			}
			else {
				openModal('login-message', { message: 'Favorilerinizi düzenlemek için üye girişi yapmalısınız.' })
				reject();
			}
		});
	}, [loggedIn])

	const toggleAlarm = useCallback((product, mode) => {
		if(alarmProductsRef.current[mode][product.serial_id]) {
			removeAlarm(product, mode);
		}
		else {
			addAlarm(product, mode);
		}

	}, [loggedIn, addAlarm, removeAlarm])

	const addAlarm = useCallback((product, mode = 'price') => {
		return new Promise((resolve, reject) => {
			if(loggedIn) {
				setAlarmsBusy(true);
				
				userServices.addAlarmProduct(product, mode).then((addedProduct) => {
					setAlarmProducts({
						...alarmProductsRef.current,
						[mode]: {
							...alarmProductsRef.current[mode],
							[product.serial_id]: {
								...product,
								alarm_id: addedProduct.alarm_id,
							}
						}
					});

					resolve(addedProduct);
				}).catch(() => {
					reject();
				}).finally(() => {
					setAlarmsBusy(false);
				})
			}
			else {
				openModal('login-message', { message: `Bu ürünü ${mode === 'price' ? 'fiyat' : 'stok'} listenize eklemek için üye girişi yapmalısınız.`})
				reject();
			}
		})
	}, [loggedIn])

	const removeAlarm = useCallback((product, mode = 'price') => {
		return new Promise((resolve, reject) => {
			if(loggedIn) {
				setAlarmsBusy(true);
				
				userServices.removeAlarmProduct(alarmProductsRef.current[mode][product.serial_id], mode).then(() => {
					setAlarmProducts({
						...alarmProductsRef.current,
						[mode]: omit(alarmProductsRef.current[mode], [product.serial_id]),
					})
					resolve(product);
				}).catch(() => {
					reject();
				}).finally(() => {
					setAlarmsBusy(false);
				})
			}
			else {
				openModal('login-message', { message: `Bu ürünü ${mode === 'price' ? 'fiyat' : 'stok'} listenize eklemek için üye girişi yapmalısınız.`});
				reject();
			}
		});

	}, [loggedIn])

	useEffect(() => {
		if(loggedIn) {
			if(!favoriteProductsRef.current) {
				fetchFavoriteProducts();
			}
			if(!alarmProductsRef.current) {
				fetchAlarmProducts();
			}
		}
		else {
			setFavoriteProducts(null);
			setAlarmProducts(null);
		}
	}, [fetchFavoriteProducts, fetchAlarmProducts, loggedIn])

	return (
		<WishlistContext.Provider value={{
			favoriteBusy: loggedIn ? favoriteBusy : false,
			alarmsBusy: loggedIn ? alarmsBusy : false,
			fetchFavoriteProducts,
			fetchAlarmProducts,
			toggleFavorite,
			toggleAlarm,
			removeFavorite,
			addAlarm,
			removeAlarm,
			favoriteProducts: favoriteProducts ?? {},
			alarmProducts: alarmProducts ?? {
				price: {},
				stock: {},
			}
		}}>
			{children}
		</WishlistContext.Provider>
	)
}

WishlistProvider.propTypes = {
	children: PropTypes.node,
}