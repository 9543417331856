import request from '@wearetla/tla-essentials-tools/utilities/request';
import pick from 'lodash/pick'

// Functions
import parseListingFilters from '/functions/parse-listing-filters'
import parseRequestQuery from '/functions/parse-request-query'

const productServices = {
	// Product List
	getProducts: (query, ctx) => {
		return new Promise((resolve, reject) => {
			request.get(`products`, parseRequestQuery(query, 'brand'), (payload, status, request) => {
				if (status === 200 && payload && payload.products) {
					resolve({
						products: payload.products,
						pagination: request.data.pagination,
					});
				}
				else {
					reject(request);
				}
			}, { showMessages: false, cache: true, ctx: ctx });
		});
	},
	getProductsFilters: (filterType = 'search', ctx) => {
		return new Promise((resolve, reject) => {
			request.get(`products/filters`, false, (payload, status, request) => {
				if (payload) {
					resolve(parseListingFilters(payload, filterType));
				}
				else {
					reject(request);
				}
			}, { showMessages: false, cache: true, ctx: ctx });
		});
	},
	// Category
	getCategoryFilters: (categoryID, inStock, ctx) => {
		return new Promise((resolve, reject) => {
			request.get(`categories/${categoryID}/filters${inStock ? '?stock=1' : ''}`, {}, (payload, status, request) => {
				if (payload) {
					resolve(parseListingFilters(payload, 'category'));
				}
				else {
					reject(request);
				}
			}, { showMessages: false, cache: true, ctx: ctx });
		});
	},
	getCategoryProducts: (categoryID, query, ctx) => {
		return new Promise((resolve, reject) => {
			request.get(`categories/${categoryID}/products`, parseRequestQuery(query, 'category'), (payload, status, request) => {
				if (status === 200 && payload && payload.products && payload.category) {
					resolve({
						products: payload.products,
						category: payload.category,
						tags: payload.tags,
						pagination: request.data.pagination,
					});
				}
				else {
					reject(request);
				}
			}, { showMessages: false, cache: true, ctx: ctx });
		});
	},
	getCategoryBanners: (categoryID, ctx) => {
		return new Promise((resolve, reject) => {
			request.get(`categories/${categoryID}/banner`, {}, (payload, status, request) => {
				if (status === 200 && payload && payload.banners) {
					resolve(payload.banners);
				}
				else {
					reject(request);
				}
			}, { showMessages: false, cache: true, ctx: ctx });
		});
	},
	// Brand
	getBrandProducts: (brandID, query, ctx) => {
		return new Promise((resolve, reject) => {
			// const {stock, ...submitQuery} = query;
			request.get(`brand/${brandID}/products`, parseRequestQuery({...query}, 'search'), (payload, status, request) => {
				if (status === 200 && payload && payload.products) {
					resolve({
						brandData: payload.brand,
						products: payload.products,
						pagination: request.data.pagination,
					});
				}
				else {
					reject(request);
				}
			}, { showMessages: false, cache: true, ctx: ctx });
		});
	},
	getBrandFilters: (brandID, ctx) => {
		return new Promise((resolve, reject) => {
			request.get(`brand/${brandID}/filters`, false, (payload, status, request) => {
				if (payload) {
					resolve(parseListingFilters(payload, 'brand'));
				}
				else {
					reject(request);
				}
			}, { showMessages: false, cache: true, ctx: ctx });
		});
	},
	// Custom List
	getCustomListProducts: (listID, query) => {
		return new Promise((resolve, reject) => {
			request.get(`customlists/${listID}/products`, parseRequestQuery(query, 'customList'), (payload, status, request) => {
				if (status === 200 && payload && payload.products) {
					resolve({
						listData: payload.custom_list,
						products: payload.products,
						pagination: request.data.pagination,
					});
				}
				else {
					reject(status);
				}
			}, { showMessages: false, cache: true });
		});
	},
	getCustomListFilters: (listID, ctx) => {
		return new Promise((resolve, reject) => {
			request.get(`customlists/${listID}/filters`, {}, (payload, status, request) => {
				if (payload) {
					resolve(parseListingFilters(payload, 'customList'));
				}
				else {
					reject(status);
				}
			}, { showMessages: false, cache: true, ctx: ctx });
		});
	},
	// Search Results
	getSearchProducts: (searchParam, query, ctx) => {
		return new Promise((resolve, reject) => {
			// const {stock, ...submitQuery} = query;
			request.get(`search/products`, parseRequestQuery({query: searchParam, ...query}, 'search'), (payload, status, request) => {
				if (status === 200 && payload && payload.products) {
					resolve({
						products: payload.products,
						pagination: request.data.pagination,
					});
				}
				else {
					reject(request);
				}
			}, { showMessages: false, cache: true, ctx: ctx });
		});
	},
	getSearchFilters: (searchParam, inStock, ctx) => {
		return new Promise((resolve, reject) => {
			const getParams = { query: searchParam };

			if(inStock) {
				getParams.stock = 1;
			}

			request.get(`search/filters`, getParams, (payload, status, request) => {
				if (payload) {
					resolve(parseListingFilters(payload, 'search'));
				}
				else {
					reject(request);
				}
			}, { showMessages: false, cache: true, ctx: ctx });
		});
	},
	getAutocompleteResults: (searchParam) => {
		return new Promise((resolve, reject) => {
			request.post('search/autocomplete', { query: searchParam }, (payload, status, request) => {
				if (status === 200 && payload) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			}, { showMessages: false });
		});
	},
	getPopularSearches: () => {
		return new Promise((resolve, reject) => {
			request.get('search/popular', false, (payload, status, request) => {
				if (status === 200 && payload) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			}, { showMessages: false });
		});
	},
	// Product Detail
	getProduct: (productID) => {
		return new Promise((resolve, reject) => {
			request.get(`products/${productID}`, { kind: true }, (payload, status) => {
				if (payload && payload.product) {
					resolve({
						...payload.product,
						displayKinds: payload.product.kinds.filter(kind => kind.product_status !== 0),
						kinds: (payload.product.kinds ?? []).map((kind => ({
							...kind,
							displaySerials: kind.serials.filter(serial => !!serial.title.length),
						}))),
						comment_average_string: payload.product.comment_average.toString().replace('.', ','),
					});
				}
				else {
					reject(status);
					// reject(request);
				}
			}, { showMessages: false, cache: true });
		});
	},
	getProductComments: (productID) => {
		return new Promise((resolve, reject) => {
			request.get(`products/${productID}/comments`, false, (payload, status, request) => {
				if(payload && payload.comments) {
					resolve(payload.comments);
				}
				else {
					reject(request);
				}
			})
		})
	},
	addProductComment: (productID, formData) => {
		return new Promise((resolve, reject) => {
			request.post(`products/${productID}/comments`, formData, (payload, status, request) => {
				if(status === 201) {
					resolve(request.data.meta.messages.success.join(', '));
				}
				else {
					reject(request.data.meta ? request.data.meta.messages : 'Değerlendirmeniz gönderilirken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
				}
			}, {
				showMessages: [],
			})
		})
	},
	getProductInstallments: (product) => {
		return new Promise((resolve, reject) => {
			request.post(`installments`, { price: product.price.original }, (payload, status, request) => {
				if(status === 200 && payload) {
					resolve(payload);
				}
				else {
					reject(request.data.meta ? request.data.meta.messages : 'Taksit seçenekleri getirilirken gönderilirken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
				}
			})
		})
	},
	getProductCampaigns: (serialID) => {
		return new Promise((resolve, reject) => {
			request.get(`products/${serialID}/campaigns`, false, (payload, status, request) => {
				if (payload && payload.campaigns) {
					resolve(payload.campaigns);
				}
				else {
					reject(status);
				}
			}, { showMessages: false, cache: true});
		});
	},
	getProductStoreStocks: (productSerial) => {
		return new Promise((resolve, reject) => {
			request.get(`products/${productSerial}/stores`, false, (payload, status, request) => {
				if (payload && payload.stores) {
					resolve(payload.stores);
				}
				else {
					reject(status);
					// reject(request);
				}
			}, { showMessages: false });
		});
	},
	getProductProperties: (productID, ctx) => {
		return new Promise((resolve, reject) => {
			request.get(`products/${productID}/properties`, false, (payload, status, request) => {
				if (payload && payload.properties) {
					resolve(payload.properties);
				}
				else {
					// reject(status);
					reject(request);
				}
			}, { showMessages: false, cache: true, ctx: ctx });
		});
	},
	compareProductProperties: (ids) => {
		return new Promise((resolve, reject) => {
			request.get(`products/properties`, {
				product_ids: ids,
			}, (payload, status, request) => {
				if (payload && payload.properties) {
					let indexedProperties = [];
					for(const property in payload.properties) {
						indexedProperties.push({
							key: property,
							params: payload.properties[property],
						})
					}
					resolve({
						products: payload.products.map(p => ({
							...pick(p, [
								'id',
								'name',
								'price',
								'old_price',
								'discount_ratio',
								'slug',
								'comment_count',
								'comment_average',
								'barcode',
								'brand',
								'gender_name',
								'madein',
								'product_code',
							]),
							name: p.product_name,
							image: p.images.length ? (p.image_types.thumbnail + p.images[0].url) : false,
						})),
						properties: indexedProperties
					});
				}
				else {
					// reject(status);
					reject(request);
				}
			}, { showMessages: false });
		});
	},
	getRelatedProducts: (productID) => {
		return new Promise((resolve, reject) => {
			request.get(`products/${productID}/relations`, false, (payload, status, request) => {
				if(status === 200 && payload && payload.relations) {
					resolve(payload.relations);
				}
				else {
					reject(request.data.meta ? request.data.meta.messages : 'İlgili  ürünler getirilirken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
				}
			})
		})
	},
	
}

export default productServices;