import { useEffect, useRef } from "react";

// Context
import { useGlobalState } from "@wearetla/tla-essentials-tools/utilities/global-state";
import { useBreakpoints } from "@wearetla/tla-essentials-tools/utilities/breakpoints";
import { useNavigator } from "@wearetla/tla-essentials-tools/utilities/navigator";

// Services
import siteServices from '/services/site';

// Functions
import getMobileOS from "/functions/get-mobile-os";

const getFeaturedCategories = () => {
	return siteServices.getFeaturedCategories();
}

const getFooterProducts = () => {
	return siteServices.getFooterProducts();
}

// const getCategories = () => {
// 	return siteServices.getCategories();
// }

const getRootCategories = () => {
	return siteServices.getRootCategories();
}

const getFaqs = () => {
	return siteServices.getFaqs();
}

const getTopBannerData = (mobile) => {
	return siteServices.getBanners(mobile ? 13 : 10);
}

const getSysParameters = () => {
	return siteServices.getInitialSysParameters();
}

// Setters
const formatTopBannerData = (data, mobile) => {
	return { data: data.length > 0 ? data[0] : false, mobile }
}

export const GlobalStateController = () => {
	const { categories, topBannerData, featuredCategories, footerProducts, faqs, sysParameters, setData } = useGlobalState();
	const { mobile } = useBreakpoints();
	const { activeRoute } = useNavigator();
	const activeRouteDataRef = useRef(activeRoute)

	useEffect(() => {
		window.$babyMall = {};

		setData('clientOS', getMobileOS());
	}, [])

	useEffect(() => {
		activeRouteDataRef.current = activeRoute;
		// if(activeRoute.head) {
		// 	setHeadData({
		// 		route: match?.route,
		// 		data: parseHead(match.route.head)
		// 	})
		// }

		
		window.$babyMall.prevLocation = (window.$babyMall.curLocation && window.$babyMall.curLocation.pathname !== window.location.pathname) ? window.$babyMall.curLocation : window.$babyMall.prevLocation;

		window.$babyMall.curLocation = {
			origin: window.location.origin,
			pathname: window.location.pathname,
			href: window.location.href,
		}
	}, [activeRoute])

	// CSR fallback.
	useEffect(() => {
		if(!categories) {
			getRootCategories().then((categories) => {
				setData('categories', categories);
			});
		}
	}, [categories]);

	useEffect(() => {
		if(topBannerData?.mobile !== mobile) {
			getTopBannerData(mobile).then((data) => {
				setData('topBannerData', formatTopBannerData(data, mobile));
			});
		}
	}, [topBannerData, mobile]);

	useEffect(() => {
		if(!featuredCategories) {
			getFeaturedCategories().then((featuredCategories) => {
				setData('featuredCategories', featuredCategories);
			});
		}
	}, [featuredCategories])
	
	useEffect(() => {
		if(!footerProducts) {
			getFooterProducts().then((footerProducts) => {
				setData('footerProducts', footerProducts);
			});
		}
	}, [footerProducts])

	useEffect(() => {
		if(!faqs) {
			getFaqs().then((faqs) => {
				setData('faqs', faqs);
			});
		}
	}, [faqs])

	useEffect(() => {
		if(!sysParameters) {
			getSysParameters().then((sysParameters) => {
				setData('sysParameters', sysParameters);
			});
		}
	}, [sysParameters])

	return null;
}

GlobalStateController.getServerProps = ({ mediaStatus, isApplication }) => {
	const isMobile = mediaStatus?.mobile ?? false;

	return new Promise((resolve) => {
		Promise.all([
			getRootCategories(),
			getFeaturedCategories(),
			getFaqs(),
			getTopBannerData(isMobile),
			getSysParameters(),
			getFooterProducts(),
		]).then(([categories, featuredCategories, faqs, topBannerData, sysParameters, footerProducts]) => {
			resolve({
				categories,
				featuredCategories,
				faqs,
				topBannerData: formatTopBannerData(topBannerData, isMobile),
				sysParameters,
				isApplication,
				footerProducts,
			})
		}).catch(e => {
			console.error('Initial props error: ', e);
			resolve({
			})
		})
	})
}